import React, { useState } from 'react'

import { useTranslation } from "react-i18next"
import { useSearchParams, useLocation } from 'react-router-dom'
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"

import AuthCard from '../../components/AuthCard'
import GuestLayout from '../../components/Layouts/GuestLayout'
import axios from '../../lib/axios'
import { useStateContext } from '../../context/ContextProvider'
import { useAuth } from '../../hooks/auth'
import Wrapper from '../../components/Wrapper'
import Loading from '../../components/Loading'

const LoginExternal = () => {

    const { t } = useTranslation()
    const { config, setChoosesite } = useStateContext()
    const [params] = useSearchParams();
    const [code, setCode] = useState(params.get('code') || '')
    const location = useLocation()
    const { pathname } = location

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [status, setStatus] = useState(null)

    const getRedirectionRoute = (code) => {
        switch(pathname) {
            case "/broker-sales":
                return `/broker-sales/${code}`
            /* case "/broker-purchase":
                return `/broker-purchase/${code}` */
            default:
                return `/3pl/${code}`
        }
    }

    const { user, login, loginImpersonate } = useAuth({
        middleware: 'auth.code',
        redirectIfAuthenticated: getRedirectionRoute(code),
    })

    const validate3PLAccessCode = async () => {
        setIsLoading(true)

        const formData = {}
        formData['tpl_access_code'] = code

        await axios
            .post(`/api/3pl/validate`, formData, config)
            .then(({ data }) => {
                const driver = data.success.driver

                if(user) {
                    loginImpersonate({
                        id: driver?.id,
                        impersonator_id: user?.id,
                        impersonatee_name: driver?.name,
                        impersonatee_site_id: driver?.site_id,
                        impersonatee_company_id: driver?.company_id,
                        role: driver?.role,
                        site_id: localStorage.getItem('site'),
                        company_id: localStorage.getItem('company_id'),
                        setErrors,
                        setStatus,
                    })
                } else {
                    setChoosesite(driver?.site_id)
                    localStorage.setItem('site', driver?.site_id)
                    login({
                        email: driver?.email,
                        password: 'tplpass',
                        setErrors,
                        setStatus,
                    })
                    let filter = [{}]
                    localStorage.setItem('filters', JSON.stringify(filter))
                }
            })
            .catch(({ response }) => {
                setErrors(response.data.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const validateBrokerAccessCode = async () => {
        setIsLoading(true)

        const formData = {}
        formData['service_provider_access_code'] = code

        await axios
            .post(`/api/broker-sales/validate`, formData, config)
            .then(({ data }) => {
                const serviceProvider = data.success.service_provider

                if(user) {
                    loginImpersonate({
                        id: serviceProvider?.id,
                        impersonator_id: user?.id,
                        impersonatee_name: serviceProvider?.name,
                        impersonatee_site_id: serviceProvider?.site_id,
                        impersonatee_company_id: serviceProvider?.company_id,
                        role: serviceProvider?.role,
                        site_id: localStorage.getItem('site'),
                        company_id: localStorage.getItem('company_id'),
                        setErrors,
                        setStatus,
                    })
                } else {
                    setChoosesite(serviceProvider?.site_id)
                    localStorage.setItem('site', serviceProvider?.site_id)
                    login({
                        email: serviceProvider?.email,
                        password: 'servizio',
                        setErrors,
                        setStatus,
                    })
                    let filter = [{}]
                    localStorage.setItem('filters', JSON.stringify(filter))
                }
            })
            .catch(({ response }) => {
                setErrors(response.data.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <GuestLayout>
                <AuthCard>
                    <Wrapper>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className='min-w-[400px] max-[500px]:min-w-[300px]'>
                                <div className='w-full'>
                                    <p className='text-[#718096] text-[18px] mb-3'>{pathname.includes('/broker') ? t('service_provider_access') : t('driver_access')}</p>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        placeholder={t('enter_load_access')}
                                        onChange={(e) => setCode(e.target.value)}
                                        className='w-full'
                                        type='text'
                                        value={code}
                                        required
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px', }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-lock"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <p className={`text-sm text-red-600`}>
                                        {errors}
                                    </p>
                                </div>

                                <div className="flex items-center w-full pt-5">
                                    <button className="w-full rounded-md bg-[#DBA34B] hover:bg-[#BD7503] disabled:bg-[#B8B7BC] text-white uppercase py-3 font-[700] text-[16px]" onClick={pathname.includes('/broker') ? validateBrokerAccessCode : validate3PLAccessCode}>{t("login")}</button>
                                </div>
                            </div>
                        </form>
                    </Wrapper>
                </AuthCard>
            </GuestLayout>
        </>
    )
}

export default LoginExternal
