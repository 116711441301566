import React from 'react'

import { useTranslation } from 'react-i18next'

const BrokerPurchaseOrderButtons = ({
    statusSlug,
    confirmHandler,
    immutable,
    disabled,
    blocked,
    user
}) => {
    const { t } = useTranslation()

    return (
        <>
            {
                (
                    (
                        statusSlug === 'completed_by_driver'
                        || statusSlug === 'on_site'
                    )
                    && user?.role === 'vendor_user'
                    && !immutable
                ) ? (
                    <button type="button" onClick={confirmHandler} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#049238] hover:bg-[#006425] disabled:bg-[#B8B7BC]'>
                        <span className='pr-2'><i className="fa-solid fa-check"></i></span> {t('confirm')}
                    </button>
                ) : null
            }
        </>
    )
}

export default BrokerPurchaseOrderButtons
