import React, { useState, useEffect } from 'react'

import { Box, Tooltip, FormControl, InputLabel, Select, IconButton, MenuItem, ListItemText, ListItemIcon, Checkbox, InputAdornment, Input, Chip } from '@mui/material'
import { useTranslation } from "react-i18next"
import { useNavigate, useLocation } from 'react-router-dom'

import AppLayout from '../../components/Layouts/AppLayout'
import TableShared from '../../components/TableShared'
import { useStateContext } from '../../context/ContextProvider'
import { useAuth } from '../../hooks/auth'
import axios from '../../lib/axios'
import ClearIcon from "@mui/icons-material/Clear"
import update from 'immutability-helper'
import { isUndefined } from 'lodash'
import Loading from '../../components/Loading'

import { handleDeleteSelectionObject, handleDeleteSelectionPrimitive, isValid } from '../../helpers/helper'

const ProceededCollections = () => {

    const { t } = useTranslation()
    const { choosesite, config, pusher, currency } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })

    const [isLoading, setIsLoading] = useState(false)
    const [purchaseOrders, setPurchaseOrders] = useState([])

    /* search options */
    const [searchStatuses, setSearchStatuses] = useState([])
    const [searchVendorAddresses, setSearchVendorAddresses] = useState([])
    const [searchReqDates, setSearchReqDates] = useState([])

    /* states */
    const [searchNo, setSearchNo] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_proceeded_collections_no || ''
        } else {
            return ''
        }
    })
    const [selectedStatuses, setSelectedStatuses] = useState([])
    const everyStatusSelected = searchStatuses.length > 0 && selectedStatuses.length === searchStatuses.length
    const [selectedVendorAddresses, setSelectedVendorAddresses] = useState([])
    const everyVendorAddressSelected = searchVendorAddresses.length > 0 && selectedVendorAddresses.length === searchVendorAddresses.length
    const [selectedReqDates, setSelectedReqDates] = useState([])
    const everyReqDateSelected = searchReqDates.length > 0 && selectedReqDates.length === searchReqDates.length

    const [openStatuses, setOpenStatuses] = useState(false)
    const [openVendorAddresses, setOpenVendorAddresses] = useState(false)
    const [openReqDates, setOpenReqDates] = useState(false)

    /* effects */

    useEffect(() => {
        if (isValid(choosesite)) {
            const collectionChannelCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
            const collectionChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
            const collectionChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)

            // Bind collection channel pushes

            collectionChannelCreate.bind(`${localStorage.getItem('client_id')}-purchaseorder-created-event-site-${choosesite}`, data => {
                if (Number(data.vendor_id) == Number(user?.vendor_id)) {
                    /* handleEventCollection(data.id, 'created') */
                    getCollections()
                }
            })

            collectionChannelDelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${choosesite}`, data => {
                if (Number(data.vendor_id) == Number(user?.vendor_id)) {
                    /* handleEventCollection(data.id, 'deleted') */
                    getCollections()
                }
            })

            collectionChannelUpdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${choosesite}`, data => {
                if (Number(data.vendor_id) == Number(user?.vendor_id)) {
                    /* handleEventCollection(data.id, 'updated') */
                    getCollections()
                }
            })
        }

        return (() => {
            if (isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        getCollections()
    }, [])

    useEffect(() => {
        setSearchOptions(purchaseOrders)
    }, [purchaseOrders])

    useEffect(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            setSelectedStatuses(filter[0]?.customer_portal_proceeded_collections_status || [])
            setSelectedVendorAddresses(filter[0]?.customer_portal_proceeded_collections_location || [])
            setSelectedReqDates(filter[0]?.customer_portal_proceeded_collections_date || [])
        }
    }, [searchStatuses, searchVendorAddresses, searchReqDates])

    useEffect(() => {
        createFilterArray(searchNo, selectedStatuses, selectedVendorAddresses, selectedReqDates)
    }, [searchNo, selectedStatuses, selectedVendorAddresses, selectedReqDates])

    /* columns */

    const columns = [
        {
            field: 'po_number',
            headerName: t('purchase_order_no'),
            flex: 0.75,
            minWidth: 150
        },
        {
            field: 'requested_collection_date',
            headerName: t('req_date'),
            flex: 0.625,
            minWidth: 125
        },
        {
            field: 'po_status_name',
            headerName: t('status'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'purchase_address_name',
            headerName: t('address'),
            flex: 1,
            minWidth: 200
        },
        {
            field: 'customer_bol_no',
            headerName: `${t('customer_bol')} #`,
            flex: 0.625,
            minWidth: 125
        },
        {
            field: 'broker_bol_no',
            headerName: `${t('broker_bol')} #`,
            flex: 0.625,
            minWidth: 125
        },
        {
            field: 'pickup_trailer_no',
            headerName: t('trailer_no'),
            flex: 0.625,
            minWidth: 125
        },
        {
            field: 'total_qty_ordered',
            headerName: t('total_qty_ordered'),
            flex: 0.7,
            minWidth: 140
        },
        {
            field: 'total_ordered_load_value',
            headerName: t('total_load_value'),
            flex: 0.7,
            minWidth: 140,
            renderCell: (params) => <TotalLoadValue params={params} currency={currency} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.75,
            minWidth: 150,
            cellClassName: 'padding-0',
            renderCell: (params) => <TodaysPurchaseOrderAction params={params} setIsLoading={setIsLoading} config={config} />
        }
    ]

    /* functions */

    const getCollections = async () => {
        setIsLoading(true)

        const url = `/api/list-orders?order_type=purchase&?vendor_id=${user?.vendor_id}&include_broker`

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data?.data
                setPurchaseOrders(data)
                setIsLoading(false)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading()
            })
    }

    const setSearchOptions = (collections) => {
        if (collections && collections.length > 0) {
            const statuses = []
            const vendorAddresses = []
            const reqDates = []

            collections.forEach(order => {
                let newStatus = {
                    id: order.po_status_id,
                    name: order.po_status_name,
                }

                let newVendorAddress = {
                    id: order.purchase_address_id,
                    name: order.purchase_address_name,
                }

                let newCustomerBOL = order.customer_bol_no

                let newBrokerBOL = order.broker_bol_no

                let newReqDate = order.requested_collection_date

                // Check if status exists already as an option
                let newStatusExists = statuses.some(function (status) {
                    return status.id === newStatus.id
                })

                if (!newStatusExists) {
                    statuses.push(newStatus);
                }

                // Check if vendor address exists already as an option
                let newVendorAddressExists = vendorAddresses.some(function (vendorAddress) {
                    return vendorAddress.id === newVendorAddress.id
                })

                if (!newVendorAddressExists) {
                    vendorAddresses.push(newVendorAddress)
                }

                // Check if requested collection date already exists as an option
                let newReqDateExists = reqDates.some(function (reqDate) {
                    return reqDate === newReqDate
                })

                if (!newReqDateExists) {
                    reqDates.push(newReqDate)
                }
            });

            statuses.sort((a, b) => {
                return a.id - b.id
            })

            vendorAddresses.sort((a, b) => {
                return a.name - b.name
            })

            reqDates.sort((a, b) => {
                return a - b
            })

            setSearchStatuses(statuses)
            setSearchVendorAddresses(vendorAddresses)
            setSearchReqDates(reqDates)
        }
    }

    const handleChangeSelection = (event, type) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((val) => {
            switch (type) {
                case 'status':
                case 'vendorAddress':
                    if (duplicateRemoved.findIndex((o) => o.id === val.id) >= 0) {
                        duplicateRemoved = duplicateRemoved.filter((x) => x.id === val.id)
                    } else {
                        duplicateRemoved.push(val)
                    }
                    break
                case 'customerBOL':
                case 'brokerBOL':
                case 'reqDate':
                    if (duplicateRemoved.findIndex((o) => o === val) >= 0) {
                        duplicateRemoved = duplicateRemoved.filter((x) => x === val)
                    } else {
                        duplicateRemoved.push(val)
                    }
                    break
                default:
            }
        })

        if (value[value.length - 1] === 'all') {
            switch (type) {
                case 'status':
                    setSelectedStatuses(selectedStatuses.length === searchStatuses.length ? [] : searchStatuses)
                    break
                case 'vendorAddress':
                    setSelectedVendorAddresses(selectedVendorAddresses.length === searchVendorAddresses.length ? [] : searchVendorAddresses)
                    break
                case 'reqDate':
                    setSelectedReqDates(selectedReqDates.length === searchReqDates.length ? [] : searchReqDates)
                    break
                default:
                    return
            }
            return
        }

        switch (type) {
            case 'status':
                setSelectedStatuses(duplicateRemoved)
                break
            case 'vendorAddress':
                setSelectedVendorAddresses(duplicateRemoved)
                break
            case 'reqDate':
                setSelectedReqDates(duplicateRemoved)
                break
            default:
                return
        }
    }

    const createFilterArray = (no, status, location, date) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].customer_portal_proceeded_collections_no = no
        filters[0].customer_portal_proceeded_collections_status = status
        filters[0].customer_portal_proceeded_collections_location = location
        filters[0].customer_portal_proceeded_collections_date = date

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    /* filters */

    const filteredByNo = purchaseOrders?.filter(data => {
        if (searchNo === null || searchNo === '') return data
        if (data.po_number?.toLocaleLowerCase().includes(searchNo.toLocaleLowerCase())) return data
    })

    const filteredByStatuses = filteredByNo?.filter(data => {
        if (selectedStatuses.length < 1) return data
        const selectedStatusIds = selectedStatuses.map((status) => status.id)
        if (selectedStatusIds.includes(Number(data.po_status_id))) return data
    })

    const filteredByVendorAddresses = filteredByStatuses?.filter(data => {
        if (selectedVendorAddresses.length < 1) return data
        const selectedVendorAddressIds = selectedVendorAddresses.map((vendorAddress) => vendorAddress.id)
        if (selectedVendorAddressIds.includes(Number(data.purchase_address_id))) return data
    })

    const filteredByReqDates = filteredByVendorAddresses?.filter(data => {
        if (selectedReqDates.length < 1) return data
        if (selectedReqDates.includes(data.requested_collection_date)) return data
    })

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='flex justify-between'>
                    <div className='p-5 w-full'>
                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='flex justify-start gap-4 items-center p-5 border-b'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('collections')}</p>
                            </div>
                            <div className='flex justify-between items-end lg:flex-row flex-col w-full pb-5 border-b'>
                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_purchase_no')} disabled={isLoading} value={searchNo} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchNo(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openStatuses}>
                                        <InputLabel id="select-statuses-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-statuses"
                                            labelId='select-statuses-label'
                                            open={openStatuses}
                                            onOpen={() => setOpenStatuses(true)}
                                            onClose={() => setOpenStatuses(false)}
                                            disabled={isLoading}
                                            value={selectedStatuses}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`s_option-${x.id}`}
                                                            label={x.name}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionObject(e, x.id, selectedStatuses, setSelectedStatuses, setOpenStatuses)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            onChange={(e) => handleChangeSelection(e, 'status')}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedStatuses.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedStatuses ? (<IconButton sx={{ visibility: (selectedStatuses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedStatuses([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                searchStatuses?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyStatusSelected} indeterminate={selectedStatuses.length > 0 && selectedStatuses.length < searchStatuses.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }

                                            {
                                                searchStatuses?.map((status) =>
                                                    <MenuItem value={status} key={`s${status.id}`}>
                                                        <Checkbox checked={selectedStatuses?.findIndex((i) => i.id === status.id) >= 0} />
                                                        <ListItemText primary={status.name} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openVendorAddresses}>
                                        <InputLabel id="select-vendor-addresses-label">{t('search_by_purchase_location')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-vendor-addresses"
                                            labelId='select-vendor-addresses-label'
                                            open={openVendorAddresses}
                                            onOpen={() => setOpenVendorAddresses(true)}
                                            onClose={() => setOpenVendorAddresses(false)}
                                            disabled={isLoading}
                                            value={selectedVendorAddresses}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`va_option-${x.id}`}
                                                            label={x.name}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionObject(e, x.id, selectedVendorAddresses, setSelectedVendorAddresses, setOpenVendorAddresses)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            onChange={(e) => handleChangeSelection(e, 'vendorAddress')}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedVendorAddresses.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedVendorAddresses ? (<IconButton sx={{ visibility: (selectedVendorAddresses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedVendorAddresses([])}><ClearIcon /></IconButton>) : false}
                                        >

                                            {
                                                searchVendorAddresses?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyVendorAddressSelected} indeterminate={selectedVendorAddresses.length > 0 && selectedVendorAddresses.length < searchVendorAddresses.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                            }
                                            {
                                                searchVendorAddresses?.map((vendorAddress) =>
                                                    <MenuItem value={vendorAddress} key={`pa${vendorAddress.id}`}>
                                                        <Checkbox checked={selectedVendorAddresses?.findIndex((i) => i.id === vendorAddress.id) >= 0} />
                                                        <ListItemText primary={vendorAddress.name} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openReqDates}>
                                        <InputLabel id="select-requested-collection-dates-label">{t('search_by_requested_collection_date')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-requested-collection-dates"
                                            labelId='select-requested-collection-dates-label'
                                            open={openReqDates}
                                            onOpen={() => setOpenReqDates(true)}
                                            onClose={() => setOpenReqDates(false)}
                                            disabled={isLoading}
                                            value={selectedReqDates}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`rcd_option-${x}`}
                                                            label={x}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionPrimitive(e, x, selectedReqDates, setSelectedReqDates, setOpenReqDates)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            onChange={(e) => handleChangeSelection(e, 'reqDate')}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedReqDates.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedReqDates ? (<IconButton sx={{ visibility: (selectedReqDates.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedReqDates([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                searchReqDates?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyReqDateSelected} indeterminate={selectedReqDates.length > 0 && selectedReqDates.length < searchReqDates.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                searchReqDates?.map((reqDate) =>
                                                    <MenuItem value={reqDate} key={reqDate}>
                                                        <Checkbox checked={selectedReqDates?.findIndex((i) => i === reqDate) >= 0} />
                                                        <ListItemText primary={reqDate} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <TableShared columns={columns} items={filteredByReqDates} />
                        </div>

                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default ProceededCollections

const TodaysPurchaseOrderAction = (params) => {

    const { id, uploaded_bol, uploaded_driver_bol, broker_transaction } = params.params.row

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const getPodUrl = (
        stream = 'view',
        party = 'customer'
    ) => {
        if(uploaded_driver_bol)
            return uploaded_driver_bol?.document_url

        let urlStem = `purchase-orders/${id}`

        if(broker_transaction) {
            if(broker_transaction?.uploaded_bol)
                return broker_transaction.uploaded_bol.document_url

            urlStem = `broker-transactions/${broker_transaction?.id}`
        } else {
            if(uploaded_bol)
                return uploaded_bol.document_url
        }

        const urlRoot = `${process.env.REACT_APP_BACKEND_URL}/api`
        const urlLeaves = `proof-of-delivery?stream=${stream}&party=${party}&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`

        return `${urlRoot}/${urlStem}/${urlLeaves}`
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('open')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <button onClick={() => navigate(`/purchase-order/${id}`, { state: { prevPathname: location.pathname } })} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></button>
                    </div>
                </Tooltip>

                <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <a href={getPodUrl()} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

const TotalLoadValue = (params) => {
    const loadValue = params.params.row.total_ordered_load_value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    const formattedLoadValue = params.currency ? `${params.currency.prefix}${loadValue}${params.currency.suffix}` : `${loadValue}`

    return (
        <>{formattedLoadValue}</>
    )
}
