import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate, useLocation } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import update from 'immutability-helper'
import { Tooltip } from '@mui/material'
import i18next from 'i18next'
import allLocales from '@fullcalendar/core/locales-all'
import { useTranslation } from 'react-i18next'
import { isNull, includes } from 'lodash'

import AppLayout from '../../../../components/Layouts/AppLayout'
import { useStateContext } from '../../../../context/ContextProvider'
import axios from '../../../../lib/axios'
import './ProductionOrderBoardCalendar.css'
import Loading from '../../../../components/Loading'
import { isValid } from '../../../../helpers/helper'
import NavigationProductionOrders from '../../../../components/production/NavigationProductionOrders'

const ProductionOrderBoardCalendar = () => {

    const { t } = useTranslation()
    const [orders, setOrders] = useState([])
    const { config, choosesite, setChoosesite, pusher } = useStateContext()
    const calendarRef = useRef([])
    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [initialView, setInitialView] = useState(() => {
        if(localStorage.getItem('filters') !== null) {
            const filters = JSON.parse(localStorage.getItem('filters'))
            if(filters[0]?.calendar_initial_view && includes(['dayGridMonth', 'timeGridWeek', 'timeGridDay'], filters[0]?.calendar_initial_view)) {
                return filters[0]?.calendar_initial_view
            } else {
                return 'dayGridMonth'
            }
        } else {
            return 'dayGridMonth'
        }
    })

    const [events, setEvents] = useState([])

    const getOrders = async (siteId = null) => {
        setIsLoading(true)
        await axios.get(`/api/list-orders?order_type=production&site_id=${isNull(siteId) ? localStorage.get('site') : siteId}&pending=true`, config)
            .then(res => {
                const data = res.data?.data
                setOrders(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            getOrders(choosesite)
        }
    }, [choosesite])

    useEffect(() => {
        if(isValid(choosesite)) {
            const channelProductionOrderDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
            const channelProductionOrderCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
            const channelProductionOrderUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)

            channelProductionOrderDelete.bind(`${localStorage.getItem('client_id')}-productionorder-deleted-event-site-${choosesite}`, data => {
                setOrders((prev) => {
                    const item = prev.find((i) => i.id === data.id)
                    const exItem = prev.filter((i) => i.id !== item.id)
                    return exItem
                })
            })

            channelProductionOrderCreate.bind(`${localStorage.getItem('client_id')}-productionorder-created-event-site-${choosesite}`, data => {
                getProductionOrder(data.id, 'created')
            })

            channelProductionOrderUpdate.bind(`${localStorage.getItem('client_id')}-productionorder-updated-event-site-${choosesite}`, data => {
                getProductionOrder(data.id, 'updated')
            })
        }


        return (() => {
            if(isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-productionorder-updated-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        setEvents([])
        orders.map((i) => {
            const newEvent = {
                "order_id": i.id,
                "title": i.no,
                "date": i.start_date + 'T' + i.start_time_time,
                "start_date": i.start_date,
                "due_date": i.due_date,
                "customer_name": i.customer_name ?? null
            }

            setEvents((prev) => [...prev, newEvent])
        })
    }, [orders])

    const getProductionOrder = async (id, state) => {
        setIsLoading(true)
        await axios.get(`/api/production-orders/${id}`, config)
            .then(res => {
                const productionOrder = res.data
                if (state === 'created') {
                    setOrders((prev) => [...prev, productionOrder])
                }

                if (state === 'updated') {
                    setOrders((prev) => {
                        const index = prev?.findIndex((i) => i.id === id)
                        if (index < 0) {
                            const items = [...prev, productionOrder]
                            return items
                        } else {
                            if (Number(productionOrder.status?.id) > 2 || Number(productionOrder.status) > 2) {
                                return update(prev, { $splice: [[index, 1]] })
                            } else {
                                return update(prev,
                                    { [index]: { $set: productionOrder } }
                                )
                            }
                        }
                    })
                }
            })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const handleEventClick = (eventInfo) => {
        localStorage.setItem('initialDateProduction', eventInfo.event.extendedProps.start_date)
        navigate(`/production-order/${eventInfo.event.extendedProps.order_id}`, { state: { prevPathname: location.pathname } });
    }

    return (
        <>
        {isLoading ? <Loading /> : null}
            <AppLayout>
                <NavigationProductionOrders route='production-order-board' type='calendar' />
                <div className='p-5 w-full'>
                    <div className='p-2 bg-white mb-2 rounded-md production'>
                        <FullCalendar
                            locales={allLocales}
                            locale={i18next?.resolvedLanguage ?? 'en'}
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay'
                            }}
                            buttonText={{
                                today: t('today'),
                                month: t('month'),
                                week: t('week'),
                                day: t('day')
                            }}
                            customButtons={{
                                today: {
                                    text: t('today'),
                                    click: () => {
                                        localStorage.removeItem('initialDateProduction')
                                        let calendarApi = calendarRef.current.getApi()
                                        calendarApi.today()
                                    }
                                }
                            }}
                            initialView={initialView}
                            datesSet={(args) => {
                                if (localStorage.getItem('filters') === null) {
                                    let filter = [{}]
                                    localStorage.setItem('filters', JSON.stringify(filter))
                                }

                                let filters = JSON.parse(localStorage.getItem('filters'))

                                filters[0].calendar_initial_view = args.view.type

                                localStorage.setItem('filters', JSON.stringify(filters))
                            }}
                            events={events}
                            eventContent={RenderEventContent}
                            dayMaxEvents={true}
                            eventMaxStack={1}
                            expandRows={true}
                            eventColor='#ffffff'
                            initialDate={localStorage.getItem('initialDateProduction')}
                            eventClick={handleEventClick}
                        />
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default ProductionOrderBoardCalendar

const RenderEventContent = (eventInfo) => {
    return (
        <Tooltip disableInteractive  placement='bottom' title={
            <div className='flex flex-col'>
              <p className='text-[16px] pb-3'>{eventInfo.event.title}</p>
              <i className='text-[14px]'>{eventInfo.event.extendedProps.customer_name}</i>
            </div>
          }>
            <button className='w-full'>
                <div className='p-1 flex flex-col text-left bg-[#1ab800] text-white rounded-sm'>
                    <p className='text-xl bold p-1 roboto'>{eventInfo.event.title}</p>
                    <i className='text-[14px]'>{eventInfo.event.extendedProps.customer_name}</i>
                </div>
            </button>
        </Tooltip>
    )
}



