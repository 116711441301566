import React, {useEffect, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Autocomplete, Box, FormControl, MenuItem, Modal, Select, TextField, Tooltip, Stack, RadioGroup, FormControlLabel, Radio} from '@mui/material'
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import {Form, useNavigate} from "react-router-dom";
import MenuBookIcon from '@mui/icons-material/MenuBook'

import style from '../../styles/style'
import {useStateContext} from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableShared from '../TableShared'
import AddButton from '../AddButton'
import AddSalesOrderItem from '../sales_purchase_orders/AddSalesOrderItem'
import AddPurchaseOrderItem from '../sales_purchase_orders/AddPurchaseOrderItem';
import { createRegexFromString, deleteZero, handleAxiosError, handleInteger, handleNumber, handleZero, isValid } from '../../helpers/helper'
import { includes, toNumber } from 'lodash';
import { AUTOCOMPLETE_STYLE, MOBILE_DATE_PICKER_STYLE, MOBILE_TIME_PICKER_STYLE, SELECT_STYLE, TEXTFIELD_STYLE } from '../../constants/constants';
import UnevenQuantitiesModal from '../broker_transactions/UnevenQuantitiesModal';
import clsx from 'clsx'

const CreateBrokerTransaction = ({ open, handleOpen, setIsLoading }) => {
    const client_id = localStorage.getItem('client_id')

    const { t } = useTranslation()
    const { company_id, config, choosesite, currencySymbol, logisticsTypes } = useStateContext()
    const navigate = useNavigate()

    /* state */
    const [customers, setCustomers] = useState([])
    const [vendors, setVendors] = useState([])
    const [shipToLocations, setShipToLocation] = useState([])
    const [purchaseLocations, setPurchaseLocations] = useState([])
    const [salesOrderItems, setSalesOrderItems] = useState([])
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
    const [openSalesOrderItem, setOpenSalesOrderItem] = useState(false)
    const [openPurchaseOrderItem, setOpenPurchaseOrderItem] = useState(false)
    const [openUnevenQuantitiesModal, setOpenUnevenQuantitiesModal] = useState(false)

    const [disabled, setDisabled] = useState(true)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [totalPricePurchase, setTotalPricePurchase] = useState(0)
    const [totalQtyPurchase, setTotalQtyPurchase] = useState(0)
    const [disabledButtonSales, setDisabledButtonSales] = useState(true)
    const [disabledButtonPurchase, setDisabledButtonPurchase] = useState(true)

    const [type, setType] = useState('shipment')
    const [filteredLogisticsTypes, setFilteredLogisticsTypes] = useState([])

    /* form */
    const [logisticsType, setLogisticsType] = useState(null)
    const [logisticsTypeInputValue, setLogisticsTypeInputValue] = useState('')
    const [customer, setCustomer] = useState(null)
    const [customerInputValue, setCustomerInputValue] = useState('')
    const [vendor, setVendor] = useState(null)
    const [vendorInputValue, setVendorInputValue] = useState('')
    const [tplVendor, setTplVendor] = useState(null)
    const [tplVendorInputValue, setTplVendorInputValue] = useState('')
    const [deliveryCost, setDeliveryCost] = useState('')
    const [shipToAddressId, setShipToAddressId] = useState('')
    const [purchaseAddressId, setPurchaseAddressId] = useState('')

    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [deliveryInstructions, setDeliveryInstructions] = useState('')
    const [addressVendor, setAddressVendor] = useState('')
    const [zipVendor, setZipVendor] = useState('')
    const [cityVendor, setCityVendor] = useState('')
    const [stateVendor, setStateVendor] = useState('')
    const [pickupInstructions, setPickupInstructions] = useState('')

    const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(dayjs().endOf('day'))
    const [deliveryTime, setDeliveryTime] = useState('')
    const [requestedDeliveryTime, setRequestedDeliveryTime] = useState(dayjs().endOf('minute'))
    const [pickupAppointmentTime, setPickupAppointmentTime] = useState(dayjs().endOf('minute'))
    const [shippedDate, setShippedDate] = useState(dayjs().endOf('day'))
    const [customerPoNo, setCustomerPoNo] = useState('')
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [bolNo, setBolNo] = useState('')
    const [comments, setComments] = useState('')
    const [SCItems, setSCItems] = useState([])
    const [PCItems, setPCItems] = useState([])


    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '13') {
                if (open) createOrder()
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [
        open,
        type,
        logisticsType,
        customer,
        vendor,
        tplVendor,
        deliveryCost,
        shipToAddressId,
        purchaseAddressId,
        dropTrailerNo,
        pickupTrailerNo,
        customerPoNo,
        bolNo,
        requestedDeliveryDate,
        deliveryTime,
        requestedDeliveryTime,
        pickupAppointmentTime,
        shippedDate,
        salesOrderItems,
        purchaseOrderItems,
        comments,
        choosesite
    ])

    /* UseEffect */
    useEffect(() => {
        if (open) {
            if (isValid(company_id)) {
                getCustomers(company_id)
                getVendors(company_id)
            }
        }
    }, [company_id, open])

    useEffect(() => {
        if(isValid(customer?.id)) {
            getShipToLocations(customer.id)
        }
        setSalesOrderItems([])
    }, [customer])

    useEffect(() => {
        if(isValid(vendor?.id)) {
            getPurchaseLocations(vendor.id)
        }
        setPurchaseOrderItems([])
    }, [vendor])

    useEffect(() => {
        setSalesOrderItems([])

        if(
            shipToLocations.length > 0
            && customer?.id
            && shipToAddressId
        ) {
            getSalesContractItems()
        }
    }, [shipToAddressId])

    useEffect(() => {
        setPurchaseOrderItems([])

        if (
            purchaseLocations.length > 0
            && vendor?.id
            && purchaseAddressId
        ) {
            getPurchaseContractItems()
        }
    }, [purchaseAddressId])


    useEffect(() => {
        if (SCItems.length > 0) {
            handleNewItem(SCItems)
        }
    }, [SCItems])

    useEffect(() => {
        if (PCItems.length > 0) {
            handleNewItemPurchase(PCItems)
        }
    }, [PCItems])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [salesOrderItems])

    useEffect(() => {
        handleTotalPricePurchase()
        handleTotalQtyPurchase()
    }, [purchaseOrderItems])

    useEffect(() => {
        setTplVendor(null)
        setTplVendorInputValue('')
        setDeliveryCost('')
    }, [logisticsType])

    useEffect(() => {
        if(
            includes(['tpl'], logisticsType?.key)
            && (dayjs(pickupAppointmentTime).isAfter(dayjs(requestedDeliveryTime)))
        ) {
            setRequestedDeliveryTime(dayjs(pickupAppointmentTime))
        }
    }, [requestedDeliveryTime, pickupAppointmentTime, logisticsType])

    useEffect(() => {
        resetModal()
    }, [type])

    useEffect(() => {
        setFilteredLogisticsTypes(logisticsTypes.filter((ltype) => ltype.availability?.includes(type)))
    }, [logisticsTypes, type])

    /* Handlers */


    const handleNewItem = (e) => {
        e.map((item) => {
            let new_item = {
                "id": item.id,
                "item_no": item.item_no,
                "description": item.description,
                "qty_ordered": item.quantity,
                "qty_to_ship": 0,
                "qty_shipped": 0,
                "qty_loaded": 0,
                "unit_price": item.unit_price,
                "total_cost": item.quantity * item.unit_price,
                "color": item.color,
                "unit_id": item.item_id,
                "unit_of_measure_code": item.unit_of_measure_code
            }

            setSalesOrderItems((prev) => [...prev, new_item])
        })
    }

    const handleNewItemPurchase = (e) => {
        e.map((item) => {
            let new_item = {
                "id": item.id,
                "item_no": item.item_no,
                "description": item.description,
                "qty_ordered": item.quantity,
                "qty_to_receive": 0,
                "qty_received": 0,
                "qty_unloaded": 0,
                "vendor_count": 0,
                "unit_price": item.unit_price,
                "total_cost": item.quantity * item.unit_price,
                "color": item.color,
                "unit_id": item.item_id,
                "unit_of_measure_code": item.unit_of_measure_code
            }

            setPurchaseOrderItems((prev) => [...prev, new_item])
        })
    }



    const handleTotalPrice = () => {
        const sum = salesOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        const item = salesOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQty(sum)
        } else {
            setTotalQty(0)
        }
    }

    const handleTotalPricePurchase = () => {
        const sum = purchaseOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPricePurchase(sum);
    }

    const handleTotalQtyPurchase = () => {
        const item = purchaseOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQtyPurchase(sum)
        } else {
            setTotalQtyPurchase(0)
        }
    }

    const handleToggleModal = () => {
        handleOpen()
        setType('shipment')
        resetModal()
    }

    const resetModal = () => {
        setLogisticsType(null)
        setLogisticsTypeInputValue('')
        setCustomer(null)
        setCustomerInputValue('')
        setVendor(null)
        setVendorInputValue('')
        setTplVendor(null)
        setTplVendorInputValue('')
        setDeliveryCost('')
        setShipToAddressId('')
        setPurchaseAddressId('')
        setBolNo('')
        setDropTrailerNo('')
        setPickupTrailerNo('')
        setCustomerPoNo('')
        setComments('')
        setRequestedDeliveryDate(dayjs().endOf('day'))
        setDeliveryTime('')
        setRequestedDeliveryTime(dayjs().endOf('minute'))
        setPickupAppointmentTime(dayjs().endOf('minute'))
        setShippedDate(dayjs().endOf('day'))
        setSalesOrderItems([])
        setPurchaseOrderItems([])
        setDisabledButtonSales(true)
        setDisabledButtonPurchase(true)
    }

    const handleCustomerPoNo = (e) => {
        const limit = 50;
        setCustomerPoNo(e.target.value.slice(0, limit));
    }

    const handleShipToLocation = (e) => {
        setShipToAddressId(e.target.value)
        const item = shipToLocations.find(item => item.id === e.target.value)
        setAddress(item.address)
        setZip(item.zip)
        setCity(item.city)
        setState(item.state)
        setDeliveryInstructions(item?.delivery_instructions)
    }

    const handlePurchaseLocation = (e) => {
        setPurchaseAddressId(e.target.value)
        const item = purchaseLocations.find(item => item.id === e.target.value)
        setAddressVendor(item.address)
        setZipVendor(item.zip)
        setCityVendor(item.city)
        setStateVendor(item.state)
        setPickupInstructions(item?.pickup_instructions)
    }

    const handleUpdateItem = (id, quantity, unit_price) => {
        const salesOrderItemsCopy = [...salesOrderItems]
        const item = salesOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setSalesOrderItems(salesOrderItemsCopy)
    }

    const handleUpdateItemPurchase = (id, quantity, unit_price) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleUpdateItemQTY = (id, quantity, qts) => {
        const purchaseOrderItemsCopy = [...salesOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_ship = toNumber(qts)


        setSalesOrderItems(purchaseOrderItemsCopy)
    }

    const handleUpdateItemQTYPurchase = (id, quantity, qts) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_receive = toNumber(qts)


        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleOpenSalesOrderItem = () => {
        setDisabled(true)
        setOpenSalesOrderItem(!openSalesOrderItem)
    }

    const handleOpenPurchaseOrderItem = () => {
        setDisabled(true)
        setOpenPurchaseOrderItem(!openPurchaseOrderItem)
    }


    /* API Requests */

    const getSalesContractItems = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/sales-contracts?customer_id=${customer?.id}&ship_address_id=${shipToAddressId}`, config)
            .then(res => {
                const items = res.data
                setSCItems(items[0]?.sales_contract_items || [])
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseContractItems = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-contracts?vendor_id=${vendor?.id}&purchase_address_id=${purchaseAddressId}`, config)
            .then(res => {
                const items = res.data
                setPCItems(items[0]?.purchase_contract_items || [])
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getCustomers = async (id) => {
        setCustomer(null)
        setCustomerInputValue('')

        setIsLoading(true)
        await axios
            .get(`/api/customers?company_id=${id}&blocked=0`, config)
            .then(res => {
                const customers = res.data
                setCustomers(customers)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getVendors = async (id) => {
        setVendor(null)
        setVendorInputValue('')
        setTplVendor(null)
        setTplVendorInputValue('')

        setIsLoading(true)
        await axios
            .get(`/api/vendors?company_id=${id}&blocked=0`, config)
            .then(res => {
                const vendors = res.data
                setVendors(vendors)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getShipToLocations = async (id) => {
        setShipToAddressId('')
        setAddress('')
        setZip('')
        setCity('')
        setState('')
        setDeliveryInstructions('')

        setIsLoading(true)
        await axios
            .get(`/api/ship-addresses?customer_id=${id}`, config)
            .then(res => {
                const data = res.data
                setShipToLocation(data)
                setShipToAddressId(data[0]?.id || '')
                setAddress(data[0]?.address || '')
                setZip(data[0]?.zip || '')
                setCity(data[0]?.city || '')
                setState(data[0]?.state || '')
                setDeliveryInstructions(data[0]?.delivery_instructions || '')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocations = async (id) => {
        setPurchaseAddressId('')
        setAddressVendor('')
        setZipVendor('')
        setCityVendor('')
        setStateVendor('')
        setPickupInstructions('')

        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses?vendor_id=${id}`, config)
            .then(res => {
                const data = res.data
                setPurchaseLocations(data)
                setPurchaseAddressId(data[0]?.id || '')
                setAddressVendor(data[0]?.address || '')
                setZipVendor(data[0]?.zip || '')
                setCityVendor(data[0]?.city || '')
                setStateVendor(data[0]?.state || '')
                setPickupInstructions(data[0]?.pickup_instructions || '')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const createOrder = async (e) => {
        e.preventDefault()

        const salesOrderItemsOrdered = salesOrderItems.reduce((accumulator, current) => accumulator + toNumber(current.qty_ordered), 0)
        const purchaseOrderItemsOrdered = purchaseOrderItems.reduce((accumulator, current) => accumulator + toNumber(current.qty_ordered), 0)
        if(salesOrderItemsOrdered !== purchaseOrderItemsOrdered) {
            console.log(`Sales Items Ordered: ${salesOrderItemsOrdered}`)
            console.log(`Purchase Items Ordered: ${purchaseOrderItemsOrdered}`)
            /* setOpenUnevenQuantitiesModal(true) */
            Swal.fire({
                icon: "error",
                customClass: 'error',
                showCloseButton: true,
                iconColor: '#FF0000',
                text: t('ordered_quantities_not_equal_warning__alt')
            })
            return
        }

        setIsLoading(true)
        const formData = {}

        formData['type'] = type
        formData['logistics_type_id'] = logisticsType?.id
        formData['customer_id'] = customer?.id
        formData['vendor_id'] = vendor?.id
        formData['ship_address_id'] = shipToAddressId
        formData['purchase_address_id'] = purchaseAddressId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_po_no'] = customerPoNo
        formData['customer_bol_no'] = bolNo
        formData['requested_delivery_date'] = dayjs(requestedDeliveryDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['delivery_time'] = deliveryTime
        formData['shipment_date'] = dayjs(shippedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = choosesite
        formData['sales_order_items'] = salesOrderItems
        formData['purchase_order_items'] = purchaseOrderItems
        formData['comments'] = comments

        if(deliveryTime === 'appointment') {
            switch(logisticsType?.key) {
                case "delivery":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "pick_up":
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                case "tpl":
                    formData['requested_delivery_time'] = dayjs(requestedDeliveryTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    formData['pickup_appointment_time'] = dayjs(pickupAppointmentTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
                    break
                default:
            }
        }

        if(logisticsType?.key === 'tpl') {
            formData['tpl_vendor_id'] = tplVendor?.id
            formData['delivery_cost'] = deliveryCost
        }

        await axios
            .post(`/api/broker-transactions`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })

                navigate("/broker-transactions/" + data.success.broker_order.id)

                handleToggleModal()
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyOrderedSalesCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_to_ship',
            headerName: t('qty_to_ship'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyToShipSalesCell params={params} t={t} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_shipped',
            headerName: t('qty_shipped'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyShippedSalesCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceSalesCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalSalesCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            minWidth: 50,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} t={t} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} />
        },
    ]

    const columnsPurchase = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedPurchaseCell params={params} handler={handleUpdateItemPurchase} handlerQTR={handleUpdateItemQTYPurchase} />
        },
        {
            field: 'qty_to_receive',
            headerName: t('qty_to_receive'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceivePurchaseCell params={params} t={t} handlerQTR={handleUpdateItemQTYPurchase} />
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedPurchaseCell params={params} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPricePurchaseCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalPurchaseCell params={params} currencySymbol={currencySymbol} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            minWidth: 50,
            cellClassName: 'padding-0',
            renderCell: (params) => <ActionsPurchase params={params} t={t} salesOrderItems={purchaseOrderItems} setSalesOrderItems={setPurchaseOrderItems} />
        },
    ]

    const customerOptions = customers?.map(option => ({ label: option.customer_no + ' - ' + option.name, id: option.id }))
    const vendorsOptions = vendors?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))
    const tplVendorsOptions = vendors?.filter((vendor) => vendor.shipping_code == '3PL').map((option) => ({ label: option.vendor_no + ' - ' + option.name, id: option.id}))

    return (
        <>
            <Modal open={open} onClose={handleToggleModal}>
                <Box sx={style}>
                    <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                        <div className='flex items-center justify-between w-full leading-none'>
                            <p className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#148c8cb3] text-[35px] mr-4'>•</span> {t('create_new_broker')}</p>
                            <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                                <button onClick={handleToggleModal}><i className="fa-solid fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                        <div className='w-full lg:w-2/5'>
                            {/* TYPE SECTION */}
                            <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                <div className='pb-5 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('type_of_load')} *</p>
                                </div>
                                <FormControl className='w-full pb-4'>
                                    <RadioGroup
                                        name="broker-transaction-type-radio-button-group"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                        row
                                    >
                                        <FormControlLabel value="shipment" control={<Radio />} label={t('shipment')} />
                                        <FormControlLabel value="collection" control={<Radio />} label={t('collection')} />
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            {/* LOGISTICS SECTION */}
                            <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                <div className='pb-5 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('logistics')} *</p>
                                </div>
                                <div className='w-full pb-4'>
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        id="logistics-type-autocomplete"
                                        options={filteredLogisticsTypes?.map((type) => ({id: type.id, label: t(type.key), key: type.key}))}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        sx={{
                                            ...AUTOCOMPLETE_STYLE,
                                            '.MuiOutlinedInput-input': {
                                                padding: '0 !important'
                                            }
                                        }}
                                        inputValue={logisticsTypeInputValue}
                                        onInputChange={(e, value) => setLogisticsTypeInputValue(value)}
                                        value={logisticsType}
                                        onChange={(a, b) => {
                                            setLogisticsType(b)
                                            setDisabledButtonSales(false)
                                        }}
                                        renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                    />
                                </div>
                            </div>

                            <div
                                className={clsx(
                                    'flex flex-col', {
                                        /* 'flex-col-reverse': type === 'collection' */
                                    }
                                )}
                            >
                                {/* CUSTOMER section */}
                                <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                    <div className='pb-5 pt-1'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{type === 'collection' ? t('supplier') : t('customer')}</p>
                                    </div>
                                    {/* Customer */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{type === 'collection' ? t('supplier') : t('customer')} *</p>
                                        <Autocomplete
                                            disablePortal
                                            disableClearable
                                            id="customer-autocomplete"
                                            options={customerOptions}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            sx={{
                                                ...AUTOCOMPLETE_STYLE,
                                                '.MuiOutlinedInput-input': {
                                                    padding: '0 !important'
                                                }
                                            }}
                                            inputValue={customerInputValue}
                                            onInputChange={(e, value) => setCustomerInputValue(value)}
                                            value={customer}
                                            onChange={(a, b) => {
                                                setCustomer(b);
                                                setDisabledButtonSales(false)
                                            }}
                                            renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                        />
                                    </div>
                                    {/* Ship to code */}
                                    {logisticsType?.key != 'pick_up' ? (
                                        <>
                                            <div className='w-full pb-4'>
                                                <Stack
                                                    direction='row'
                                                    gap={1}
                                                    alignItems='baseline'
                                                    justifyContent='space-between'
                                                >
                                                    <p className="text-[14px] text-[#718096]">{t('ship_to_location')} *</p>
                                                    {(shipToAddressId && isValid(deliveryInstructions)) ? (
                                                        <Tooltip
                                                            title={
                                                                <div style={{ whiteSpace: 'pre-line' }}>
                                                                    {deliveryInstructions}
                                                                </div>
                                                            }
                                                        >
                                                            <Stack
                                                                direction='row'
                                                                gap={1}
                                                                alignItems='center'
                                                                className='text-[#718096]'
                                                            >
                                                                <MenuBookIcon fontSize='18px'/>
                                                                <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('delivery_instructions')}</span>
                                                            </Stack>
                                                        </Tooltip>
                                                    ) : null}
                                                </Stack>

                                                <div className='w-full'>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <Select
                                                            value={shipToAddressId}
                                                            onChange={(e) => { handleShipToLocation(e) }}
                                                            sx={SELECT_STYLE}
                                                        >
                                                            {shipToLocations?.map((item, index) => <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            {/* Address */}
                                            {
                                                shipToAddressId ?
                                                    <div className='w-full p-4 address mb-4'>
                                                        <div className='w-full'>{address}</div>
                                                        {shipToAddressId ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </>
                                    ) : (
                                        <div className='w-full p-4 address mb-4'>
                                            <div className='w-full text-[#00000061]'>{t('pick_up')}</div>
                                        </div>
                                    )}
                                    {/* Customer PO No. */}
                                    <div className='w-full'>
                                        <p className="text-[14px] text-[#718096]">{t('customer_po_no')} *</p>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            name="customer_po_no"
                                            value={customerPoNo}
                                            onChange={(e) => { handleCustomerPoNo(e) }}
                                        />
                                        <p className='w-full text-xs italic roboto text-slate-500 pt-1'><i className="fa-solid fa-circle-info"></i> {t('the_maximum_character_limit_is_x', { x: 50 })}.</p>
                                    </div>
                                </div>

                                {/* SUPPLIER section */}
                                <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                    <div className='pb-5 pt-1'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{type === 'collection' ? t('service_provider') : t('vendor')}</p>
                                    </div>
                                    {/* Vendor */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{type === 'collection' ? t('service_provider') : t('supplier')} *</p>
                                        <div className='w-full'>
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                id="vendor-autocomplete"
                                                options={vendorsOptions}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionDisabled={(option) => option.id === tplVendor?.id}
                                                sx={{
                                                    ...AUTOCOMPLETE_STYLE,
                                                    '.MuiOutlinedInput-input': {
                                                        padding: '0 !important'
                                                    }
                                                }}
                                                inputValue={vendorInputValue}
                                                onInputChange={(e, value) => setVendorInputValue(value)}
                                                value={vendor}
                                                onChange={(a, b) => {
                                                    setVendor(b);
                                                    setDisabledButtonPurchase(false)
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </div>
                                    </div>

                                    {/* Purchase Location */}
                                    <div className='w-full pb-4'>
                                        <Stack
                                            direction='row'
                                            gap={1}
                                            alignItems='baseline'
                                            justifyContent='space-between'
                                        >
                                            <p className="text-[14px] text-[#718096]">{t('purchaseLocation')} *</p>
                                            {(purchaseAddressId && isValid(pickupInstructions)) ? (
                                                <Tooltip
                                                    title={
                                                        <div style={{ whiteSpace: 'pre-line' }}>
                                                            {pickupInstructions}
                                                        </div>
                                                    }
                                                >
                                                    <Stack
                                                        direction='row'
                                                        gap={1}
                                                        alignItems='center'
                                                        className='text-[#718096]'
                                                    >
                                                        <MenuBookIcon fontSize='18px'/>
                                                        <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('pickup_instructions')}</span>
                                                    </Stack>
                                                </Tooltip>
                                            ) : null}
                                        </Stack>

                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    value={purchaseAddressId}
                                                    onChange={(e) => { handlePurchaseLocation(e) }}
                                                    sx={SELECT_STYLE}
                                                >
                                                    {purchaseLocations?.map((item, index) => <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {/* Address */}
                                    {
                                        purchaseAddressId ?
                                            <div className='w-full p-4 address mb-4'>
                                                <div className='w-full'>{addressVendor}</div>
                                                {purchaseAddressId ? <div className='w-full'>{zipVendor} {cityVendor}, {stateVendor}</div> : <div className='w-full'></div>}
                                            </div>
                                            :
                                            null
                                    }

                                    {/* Bol No. */}
                                    <div className='w-full'>
                                        <p className="text-[14px] text-[#718096]">{t('bol_number')}</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="bol_no"
                                                value={bolNo}
                                                onChange={(e) => { setBolNo(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 3PL COMPANY section */}
                            {logisticsType?.key === 'tpl' ? (
                                <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view mb-3'>
                                    <div className='pb-5 pt-1'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('tpl_company')}</p>
                                    </div>
                                    {/* 3PL Vendor */}
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('tpl_vendor')} *</p>
                                        <div className='w-full'>
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                id="tpl-vendor-autocomplete"
                                                options={tplVendorsOptions}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionDisabled={(option) => option.id === vendor?.id}
                                                sx={AUTOCOMPLETE_STYLE}
                                                inputValue={tplVendorInputValue}
                                                onInputChange={(e, value) => setTplVendorInputValue(value)}
                                                value={tplVendor}
                                                onChange={(a, b) => {
                                                    setTplVendor(b)
                                                    setDisabledButtonPurchase(false)
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </div>
                                    </div>

                                    <div className='w-full'>
                                        <p className="text-[14px] text-[#718096]">{t('delivery_cost')} ({currencySymbol}) *</p>
                                        <div className='w-full'>
                                            <TextField
                                                sx={TEXTFIELD_STYLE}
                                                type="text"
                                                name="delivery_cost"
                                                value={deliveryCost}
                                                onChange={(e) => handleNumber({
                                                    event: e,
                                                    setter: setDeliveryCost,
                                                    precision: 2
                                                })}
                                                onBlur={e => handleZero({
                                                    event: e,
                                                    setter: setDeliveryCost
                                                })}
                                                onFocus={e => deleteZero({
                                                    event: e,
                                                    setter: setDeliveryCost,
                                                    value: deliveryCost
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {/* GENERAL INFORMATION section */}
                            <div className='bg-white p-6 pt-0 rounded-md w-full h-fit edit-view '>
                                <div className='pb-5 pt-1'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                                </div>

                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                                    <div className='w-full'>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            name="drop_trailer_no"
                                            value={dropTrailerNo}
                                            onChange={(e) => { setDropTrailerNo(e.target.value) }}
                                        />
                                    </div>
                                </div>
                                {/* Pickup Trailer No. */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                                    <div className='w-full'>
                                        <TextField
                                            sx={TEXTFIELD_STYLE}
                                            type="text"
                                            name="pickup_trailer_no"
                                            value={pickupTrailerNo}
                                            onChange={(e) => { setPickupTrailerNo(e.target.value) }}
                                        />
                                    </div>
                                </div>

                                {/* Requested Delivery|Collection Date */}
                                <div className='w-full pb-4'>
                                    <p className="text-[14px] text-[#718096]">{type === 'collection' ? t('requested_collection_date') : t('requested_delivery_date')} *</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                sx={MOBILE_DATE_PICKER_STYLE}
                                                format="YYYY-MM-DD"
                                                value={requestedDeliveryDate}
                                                onChange={(newValue) => setRequestedDeliveryDate(newValue)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>

                                {/* Delivery Time */}
                                {!(
                                    type === 'collection'
                                    && logisticsType?.key === 'pick_up'
                                ) ? (
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('delivery_time')} *</p>
                                        <div className='w-full'>
                                            <FormControl sx={{ width: '100%' }}>
                                                <Select
                                                    value={deliveryTime}
                                                    onChange={(e) => { setDeliveryTime(e.target.value) }}
                                                    sx={SELECT_STYLE}
                                                >
                                                    <MenuItem value={'first_come_first_served'}>{t('first_come_first_served')}</MenuItem>
                                                    <MenuItem value={'appointment'}>{t('appointment')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                ) : null}

                                {/* Appointment Time(s) */}
                                {deliveryTime === 'appointment' && includes(['delivery', 'pick_up', 'tpl'], logisticsType?.key) ? (
                                    <div className={`w-full pb-4 ${logisticsType?.key === 'tpl' ? 'flex flex-row gap-x-1.5' : null}`}>
                                        {includes(['pick_up', 'tpl'], logisticsType?.key) && (
                                            <div className={`${logisticsType?.key === 'tpl' ? 'w-1/2' : 'w-full'}`}>
                                                <p className="text-[14px] text-[#718096]">{t('pickup_appointment_time')} *</p>
                                                <div className="w-full">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                            <MobileTimePicker
                                                                sx={MOBILE_TIME_PICKER_STYLE}
                                                                value={pickupAppointmentTime}
                                                                onChange={(newValue) => setPickupAppointmentTime(newValue)}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        )}
                                        {includes(['delivery', 'tpl'], logisticsType?.key) && (
                                            <div className={`${logisticsType?.key === 'tpl' ? 'w-1/2' : 'w-full'}`}>
                                                <p className="text-[14px] text-[#718096]">{t('delivery_appointment_time')} *</p>
                                                <div className='w-full'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                            <MobileTimePicker
                                                                minTime={includes(['tpl'], logisticsType?.key) ? pickupAppointmentTime : null}
                                                                sx={MOBILE_TIME_PICKER_STYLE}
                                                                value={requestedDeliveryTime}
                                                                onChange={(newValue) => setRequestedDeliveryTime(newValue)}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : null}

                                {/* Shipment Date */}
                                {!(
                                    type === 'collection'
                                    && logisticsType?.key === 'pick_up'
                                ) ? (
                                    <div className='w-full'>
                                        <p className="text-[14px] text-[#718096]">{t('shipment_date')}</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    sx={MOBILE_DATE_PICKER_STYLE}
                                                    format="YYYY-MM-DD"
                                                    value={shippedDate}
                                                    onChange={(newValue) => setShippedDate(newValue)}
                                                    disabled={client_id.includes('pallet-book') && true}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className='w-full lg:w-3/5'>
                            <div
                                className={clsx(
                                    'flex flex-col', {
                                        'flex-col-reverse': type === 'collection'
                                    }
                                )}
                            >
                                {/* Sales Order Items */}
                                <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                    <div className='pt-1 pl-3'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('salesOrderitems')}</p>
                                    </div>
                                    <div className='flex justify-end p-5 pt-0 border-b'>
                                        <Tooltip disableInteractive title={t('addsalesOrderitems')} placement='bottom'>
                                            <div>
                                                <AddButton disabled={disabledButtonSales} onClick={handleOpenSalesOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className='border-b'>
                                        {
                                            salesOrderItems.length > 0 ?
                                                <div className='flex justify-end w-full p-2'>
                                                    <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setSalesOrderItems([])}>{t('clear_all')}</button>
                                                </div>
                                                :
                                                null
                                        }
                                        <TableShared columns={columns} items={salesOrderItems} disableColumnMenu={true} />
                                    </div>
                                    <div className='flex justify-end w-full p-5'>
                                        <div className='w-full lg:w-1/3'>
                                            <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                                <p>{t('totalPrice')}</p>
                                                <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                            <div className='flex justify-between text-[#333333] text-[15px]'>
                                                <p>{t('totalQty')}</p>
                                                <p className='font-[500]'>{totalQty}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Purchase Order Items */}
                                <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                    <div className='pt-1 pl-3'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('purchaseOrderItems')}</p>
                                    </div>
                                    <div className='flex justify-end p-5 pt-0 border-b'>
                                        <Tooltip disableInteractive title={t('addpurchaseOrderItems')} placement='bottom'>
                                            <div>
                                                <AddButton disabled={disabledButtonPurchase} onClick={handleOpenPurchaseOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div className='border-b'>
                                        {
                                            purchaseOrderItems.length > 0 ?
                                                <div className='flex justify-end w-full p-2'>
                                                    <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setPurchaseOrderItems([])}>{t('clear_all')}</button>
                                                </div>
                                                :
                                                null
                                        }
                                        <TableShared columns={columnsPurchase} items={purchaseOrderItems} disableColumnMenu={true} />
                                    </div>

                                    <div className='flex justify-end w-full p-5'>
                                        <div className='w-full lg:w-1/3'>
                                            <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                                <p>{t('totalPrice')}</p>
                                                <p className='font-[500]'>{currencySymbol}{totalPricePurchase.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                            <div className='flex justify-between text-[#333333] text-[15px]'>
                                                <p>{t('totalQty')}</p>
                                                <p className='font-[500]'>{totalQtyPurchase}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full'>
                                <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                                    <div className='pt-1 p-5'>
                                        <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    </div>
                                    <div className='w-full p-5 pt-0'>
                                        <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                        <TextField
                                            multiline
                                            rows={3}
                                            value={comments}
                                            onChange={e => setComments(e.target.value)}
                                            sx={TEXTFIELD_STYLE}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex justify-end'>
                                <button type="button" onClick={createOrder} className='text-white font-[700] rounded-md py-2.5 px-5 uppercase text-[14px] bg-[#DBA34B] hover:bg-[#CF7300] disabled:bg-[#B8B7BC]'>
                                    <span className='pr-2'><i className="fa-solid fa-plus"></i></span> {t('create')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal >
            <AddSalesOrderItem open={openSalesOrderItem} handleOpen={handleOpenSalesOrderItem} salesOrderItems={salesOrderItems} setSalesOrderItems={setSalesOrderItems} disabled={disabled} setDisabled={setDisabled} />
            <AddPurchaseOrderItem open={openPurchaseOrderItem} handleOpen={handleOpenPurchaseOrderItem} purchaseOrderItems={purchaseOrderItems} setPurchaseOrderItems={setPurchaseOrderItems} disabled={disabled} setDisabled={setDisabled} />
            <UnevenQuantitiesModal
                open={openUnevenQuantitiesModal}
                setOpen={setOpenUnevenQuantitiesModal}
                continueHandler={createOrder}
                t={t}
            />
        </>
    )
}



export default CreateBrokerTransaction

const maxQtyDigits = 5

{/* SALES ORDER ITEM cells */}

const QtyOrderedSalesCell = ({ params, handler, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "" || e.target.value === '-') {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_ship) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_ship
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyToShipSalesCell = ({ params, t, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                        text: t('warningSalesOrder'),
                        icon: 'warning',
                        customClass: 'error',
                        showCloseButton: true,
                        iconColor: '#FF0000',
                        reverseButtons: true,
                    })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value,
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-ship-sales"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyShippedSalesCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-shipped-sales"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            })}
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const UnitPriceSalesCell = (params) => {
    return (
        <>{params.currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalSalesCell = (params) => {
    const [total, setTotal] = useState(params.params.value)

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{params.currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}

const Actions = ({ params, t, salesOrderItems, setSalesOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}

{/* PURCHASE ORDER ITEM cells */}

const QtyOrderedPurchaseCell = ({ params, handler, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "") {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_receive) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_receive
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-ordered-purchase"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyToReceivePurchaseCell = ({ params, t, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                    icon: "warning",
                    text: t('warningPurchaseOrder'),
                    customClass: 'warning2',
                    showCloseButton: true,
                    iconColor: '#FFCA28'
                })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-receive-purchase"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )
}

const QtyReceivedPurchaseCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-received-purchase"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            }) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const VendorCountPurchaseCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="vendor-count-purchase"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            }) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                qty
            })}
        />
    )
}

const UnitPricePurchaseCell = (params) => {
    return (
        <>{params.currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalPurchaseCell = (params) => {
    const [total, setTotal] = useState(params.params.value)

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{params.currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const ActionsPurchase = ({ params, t, salesOrderItems, setSalesOrderItems }) => {
    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
