import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Autocomplete, Box, Button, FormControl, IconButton, MenuItem, Select, Stack, Tab, TextField, Tooltip } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import TableShared from '../TableShared'
import AddButton from '../AddButton'
import AddPurchaseOrderItem from './AddPurchaseOrderItem'
import Loading from '../Loading'
import { useAuth } from '../../hooks/auth'
import UnloadingTruckIcon from '../UnloadingTruckIcon'
import PurchaseOrderButtons from '../order_buttons/PurchaseOrderButtons'
import StreetCollectionButtons from '../order_buttons/StreetCollectionButtons'
import CommentHistoryModal from '../CommentHistoryModal'
import CommentHistoryButton from '../CommentHistoryButton'
import update from 'immutability-helper'
import { deleteZero, handleAxiosError, handleInteger, handleZero, createRegexFromString, isValid } from '../../helpers/helper'
import { toNumber, isNil } from 'lodash';
import { AUTOCOMPLETE_STYLE, MOBILE_DATE_PICKER_STYLE, MOBILE_TIME_PICKER_STYLE } from '../../constants/constants'

const EditPurchaseOrder = ({ prevLocation = '/collections' }) => {

    const { t } = useTranslation()
    const { company_id, config, configFile, currencySymbol, pusher } = useStateContext()
    const params = useParams()
    const navigate = useNavigate()
    const { user } = useAuth({ middleware: 'guest' })
    const location = useLocation();

    const userHasPermissions = (targets = []) => {
        return user?.permissions.some((permission) => targets.includes(permission.name))
    }

    /* state */
    const [purchaseOrder, setpurchaseOrder] = useState([])
    const [purchaseLocations, setPurchaseLocations] = useState([])
    const [purchaseOrderItems, setPurchaseOrderItems] = useState([])
    const [openPurchaseOrderItem, setOpenPurchaseOrderItem] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [disabledFields, setDisabledFields] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [value, setValue] = useState("1")
    const [prevStatus, setPrevStatus] = useState('')
    const [driverDocs, setDriverDocs] = useState([])
    const [driverBolDoc, setDriverBolDoc] = useState(null)
    const [hasDocs, setHasDocs] = useState(false)
    const [uploadedBillOfLadingDoc, setUploadedBillOfLadingDoc] = useState({})
    const [uploadedUnloadSheetDoc, setUploadedUnloadSheetDoc] = useState({})
    const [forklifters, setForklifters] = useState([])
    const [listOf3PLVendors, setListOf3PLVendors] = useState([])
    const [SCItems, setSCItems] = useState([])
    const [brokerTransaction, setBrokerTransaction] = useState(null)

    const [openCommentHistory, setOpenCommentHistory] = useState(false)

    /* form */
    const [vendor, setVendor] = useState('')
    const [vendor3pl, setVendor3pl] = useState('')
    const [vendorName, setVendorName] = useState('')
    const [isVendorDeleted, setIsVendorDeleted] = useState(false)
    const [purchaseAddressId, setPurchaseAddressId] = useState('')
    const [address, setAddress] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [pickupInstructions, setPickupInstructions] = useState('')
    const [requestedCollectionDate, setrequestedCollectionDate] = useState(dayjs())
    const [requestedCollectionTime, setrequestedCollectionTime] = useState(dayjs())
    const [collectedDate, setcollectedDate] = useState(dayjs())
    const [customerBOL, setCustomerBOL] = useState('')
    const [brokerBOL, setBrokerBOL] = useState('')
    const [pickupTrailerNo, setPickupTrailerNo] = useState('')
    const [dropTrailerNo, setDropTrailerNo] = useState('')
    const [status, setStatus] = useState('')
    const [comments, setComments] = useState('')
    const [commentHistory, setCommentHistory] = useState([])
    const [file, setFile] = useState('')
    const inputRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const [forklifter, setForklifter] = useState('')
    const [forklifterName, setForklifterName] = useState('')
    const [loadedDate, setLoadedDate] = useState(dayjs())
    const [rejectedReason, setRejectedReason] = useState('')

    useEffect(() => {
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)

        channelupdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                getPurchaseOrder(data.id, true)
            }
        })
        channeldelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
            if (Number(data.id) == Number(params.id)) {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/purchase-order', {
                    replace: true
                })
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchase-${params.id}-document-event`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        })
    }, [])

    useEffect(() => {
        const orderDocumentUploadedChannelName = brokerTransaction
            ? `${localStorage.getItem('client_id')}-broker-${brokerTransaction?.id}-document-event`
            : `${localStorage.getItem('client_id')}-purchase-${params.id}-document-event`

        const channelOrderDocumentUploaded = pusher.subscribe(orderDocumentUploadedChannelName)

        const orderDocumentUploadedEventName = brokerTransaction
            ? `${localStorage.getItem('client_id')}-broker-${brokerTransaction.id}-document-event`
            : `${localStorage.getItem('client_id')}-purchase-${params.id}-document-event`

        channelOrderDocumentUploaded.bind(orderDocumentUploadedEventName, data => {
            if(data.is_deleted) {
                setOrderDocuments((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
                setDriverDocs((prev) => {
                    const deletedDoc = prev.find((i) => i.id === data.id)
                    const docsUpdated = deletedDoc ? prev.filter((i) => i.id !== deletedDoc.id) : prev
                    return docsUpdated
                })
                setUploadedBillOfLadingDoc((prev) => {
                    return prev.id === data.id ? {} : prev
                })
            } else {
                getDocs(brokerTransaction)
                getDriverDocs(brokerTransaction)
                getUploadedBillOfLadingDoc(brokerTransaction)
                getUploadedUnloadSheetDoc()
            }
        })

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchase-${params.id}-document-event`)
            if(brokerTransaction) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-broker-${brokerTransaction.id}-document-event`)
            }
        })
    }, [brokerTransaction])

    useEffect(() => {
        if(isValid(vendor)) {
            const channelPurchaseLocationCreated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
            const channelPurchaseLocationUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
            const channelPurchaseLocationDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
            const channelVendorDeleted = pusher.subscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)

            channelPurchaseLocationCreated.bind(`${localStorage.getItem('client_id')}-purchaseaddress-created-event`, data => {
                if(Number(data?.vendor_id) == Number(vendor)) {
                    getPurchaseLocation(data?.id)
                }
            })
            channelPurchaseLocationUpdated.bind(`${localStorage.getItem('client_id')}-purchaseaddress-updated-event`, data => {
                if(Number(data?.vendor_id) == Number(vendor)) {
                    getPurchaseLocations(vendor)
                }
            })
            channelPurchaseLocationDeleted.bind(`${localStorage.getItem('client_id')}-purchaseaddress-deleted-event`, data => {
                if(Number(data?.vendor_id) == Number(vendor)) {
                    setPurchaseLocations((prev) => {
                        const item = prev.find((i) => i.id === data.id)
                        const exItem = prev.filter((i) => i.id !== item.id)
                        return exItem
                    })
                }
            })
            channelVendorDeleted.bind(`${localStorage.getItem('client_id')}-vendor-deleted-event`, data => {
                if(Number(data?.id) == Number(vendor)) {
                    setIsVendorDeleted(true)
                }
            })
        }

        return (() => {
            if(isValid(vendor)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-created`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-updated`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseaddress-deleted`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-vendor-deleted`)
            }
        })
    }, [vendor])

    useEffect(() => {
        if(!isNil(brokerTransaction)) {
            const channelBrokerTransactionUpdated = pusher.subscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)

            channelBrokerTransactionUpdated.bind(`${localStorage.getItem('client_id')}-brokertransaction-updated-event-site-${localStorage.getItem('site')}`, data => {
                if (Number(data.id) == Number(brokerTransaction?.id)) {
                    getPurchaseOrder(params.id, true)
                }
            })
        }

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-brokertransaction-updated-site-${localStorage.getItem('site')}`)
        })
    }, [brokerTransaction])

    /* KEY EVENT */
    useEffect(() => {
        function handleKeyDown(e) {
            if (e.keyCode == '27') {
                navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : -1)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    /* documents */
    const [orderDocuments, setOrderDocuments] = useState([])


    useEffect(() => {
        getPurchaseOrder(params.id)
    }, [params.id])

    useEffect(() => {
        setPurchaseOrderItems([])
        if (purchaseAddressId == purchaseOrder?.purchase_address_id) {
            setPurchaseOrderItems(purchaseOrder?.purchase_order_items)
        }
        else {
            if (purchaseLocations.length > 0 && vendor && purchaseAddressId) {
                getPurchaseContractItems()
            }
        }
    }, [purchaseAddressId])

    const getPurchaseContractItems = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/purchase-contracts?vendor_id=${vendor}&purchase_address_id=${purchaseAddressId}`, config)
            .then(res => {
                const items = res.data
                setSCItems(items[0]?.purchase_contract_items || [])
                setIsLoading(false)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseOrder = async (id, dueToPusherEvent = false) => {
        setIsLoading(true)

        if(dueToPusherEvent) {
            setVendor('')
        }

        await axios
            .get(`/api/purchase-orders/${id}?with=brokerTransaction`, config)
            .then(res => {
                const purchaseOrder = res.data
                setStatus(purchaseOrder.po_status_id)
                setPrevStatus(purchaseOrder.prev_status_id || '')
                setVendor(purchaseOrder.vendor_id)
                setVendorName(purchaseOrder.vendor_name)
                setIsVendorDeleted(purchaseOrder.is_vendor_deleted || false)
                setDropTrailerNo(purchaseOrder.drop_trailer_no || '')
                setPickupTrailerNo(purchaseOrder.pickup_trailer_no || '')
                setCustomerBOL(purchaseOrder.customer_bol_no || '')
                setBrokerBOL(purchaseOrder.broker_bol_no || '')
                setrequestedCollectionDate(dayjs(purchaseOrder.requested_collection_date))
                setrequestedCollectionTime(dayjs(purchaseOrder.requested_collection_time))
                setcollectedDate(dayjs(purchaseOrder.collected_date))
                setPurchaseOrderItems(purchaseOrder.purchase_order_items)
                setCommentHistory(purchaseOrder.comment_history)
                setForklifter(purchaseOrder.forklift_driver_id || '')
                setForklifterName(purchaseOrder.forklift_driver_name || '')
                setLoadedDate(dayjs(purchaseOrder.unloaded_date) || dayjs())
                setpurchaseOrder(purchaseOrder)
                setVendor3pl(purchaseOrder.pl_vendor_id || '')
                setRejectedReason(purchaseOrder.rejected_data.rejected_reason || '')
                setBrokerTransaction(purchaseOrder?.broker_transaction)
                setDriverBolDoc(purchaseOrder?.uploaded_driver_bol)
                if (!dueToPusherEvent) {
                    setComments('')
                }
            }).catch(({ response }) => {
                handleAxiosError({response: response})
            }).finally(() => {
                setIsLoading(false)
            })
    }

    /* UseEffect */
    useEffect(() => {
        getDocs(brokerTransaction)
        getDriverDocs(brokerTransaction)
        getUploadedBillOfLadingDoc(brokerTransaction)
        getUploadedUnloadSheetDoc()
    }, [brokerTransaction])

    useEffect(() => {
        if (purchaseOrder?.type == 'STREET COLLECTION') {
            getForklifters()
        }
        if (purchaseOrder?.type == '3PL' && isValid(company_id)) {
            get3PLVendors(company_id)
        }
    }, [company_id, purchaseOrder])

    useEffect(() => {
        if (
            (status === 1 || status === 3) &&
            (
                ['master_admin', 'office_manager'].includes(user?.role) ||
                userHasPermissions(['daily-board-update'])
            )
        ) {
            setDisabledFields(false)
        } else {
            setDisabledFields(true)
        }
    }, [status])

    useEffect(() => {
        if (SCItems.length > 0) {
            handleNewItem(SCItems)
        }
    }, [SCItems])


    useEffect(() => {
        loadPurchaseLocation()
    }, [purchaseLocations])

    useEffect(() => {
        if (isValid(vendor)) {
            getPurchaseLocations(vendor)
        }
    }, [vendor])

    useEffect(() => {
        handleTotalPrice()
        handleTotalQty()
    }, [purchaseOrderItems, params])

    useEffect(() => {
        if (driverDocs.length < 1) {
            setHasDocs(false)
        }
    }, [driverDocs])

    /* Handlers */

    const handleNewItem = (e) => {
        e.map((item) => {
            let new_item = {
                "id": item.id,
                "item_no": item.item_no,
                "description": item.description,
                "qty_ordered": item.quantity,
                "qty_to_receive": 0,
                "qty_received": 0,
                "qty_unloaded": 0,
                "vendor_count": 0,
                "unit_price": item.unit_price,
                "total_cost": item.quantity * item.unit_price,
                "unit_id": item.item_id,
                "color": item.color,
                "unit_of_measure_code": item.unit_of_measure_code
            }

            setPurchaseOrderItems((prev) => [...prev, new_item])
        })
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleTotalPrice = () => {
        const sum = purchaseOrderItems.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.total_cost), 0);
        setTotalPrice(sum);
    }

    const handleTotalQty = () => {
        const item = purchaseOrderItems.filter((item) => item.qty_ordered !== "")
        if (item) {
            const sum = item.reduce((accumulator, currentValue) => toNumber(accumulator) + toNumber(currentValue.qty_ordered), 0);
            setTotalQty(sum)
        } else {
            setTotalQty(0)
        }
    }


    const handleCustomerPoNo = (e) => {
        setCustomerBOL(e.target.value)
    }

    const loadPurchaseLocation = () => {
        const purchaseLocation = purchaseLocations?.find(target => target.id === purchaseAddressId)
        setAddress(purchaseLocation?.address)
        setZip(purchaseLocation?.zip)
        setCity(purchaseLocation?.city)
        setState(purchaseLocation?.state)
        setPickupInstructions(purchaseLocation?.pickup_instructions)
    }

    const handlePurchaseLocation = (e) => {
        setPurchaseAddressId(e.target.value)
        const purchaseLocation = purchaseLocations.find(target => target.id === e.target.value)
        setAddress(purchaseLocation?.address)
        setZip(purchaseLocation?.zip)
        setCity(purchaseLocation?.city)
        setState(purchaseLocation?.state)
        setPickupInstructions(purchaseLocation?.pickup_instructions)
    }

    const handleUpdateItem = (id, quantity, unit_price) => {

        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_receive = toNumber(quantity)
        item.unit_price = unit_price
        item.total_cost = toNumber(quantity) * unit_price

        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleOpenpurchaseOrderItem = () => {
        setDisabled(true)
        setOpenPurchaseOrderItem(!openPurchaseOrderItem)
    }

    const handleUploadClick = () => {
        inputRef.current?.click()
    }


    const getDocs = async (brokerTransaction = null) => {
        setIsLoading(true)

        const url = brokerTransaction
            ? `/api/order-documents?order_id=${brokerTransaction?.id}&order_type=broker&document_type=order_document`
            : `/api/order-documents?order_id=${params.id}&order_type=purchase&document_type=order_document`

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data
                setOrderDocuments(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const get3PLVendors = async (id) => {
        setVendor3pl('')
        setIsLoading(true)
        await axios
            .get(`/api/vendors?company_id=${id}&shipping_code=3PL&blocked=0`, config)
            .then(res => {
                const data = res.data
                setListOf3PLVendors(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getForklifters = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/users?role=forklift_driver&site_id=${localStorage.getItem('site')}`, config)
            .then(res => {
                const data = res.data.data
                setForklifters(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getDriverDocs = async (brokerTransaction = null) => {
        setIsLoading(true)

        const url = brokerTransaction
            ? `/api/order-documents?order_id=${brokerTransaction?.id}&order_type=broker&document_type=drivers_document`
            : `/api/order-documents?order_id=${params.id}&order_type=purchase&document_type=drivers_document`

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data
                setDriverDocs(data)
                if (data.length < 1) {
                    setHasDocs(false)
                } else {
                    setHasDocs(true)
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedBillOfLadingDoc = async (brokerTransaction = null) => {
        setIsLoading(true)

        const url = brokerTransaction
            ? `/api/order-documents?order_id=${brokerTransaction?.id}&order_type=broker&document_type=proof_of_delivery`
            : `/api/order-documents?order_id=${params.id}&order_type=purchase&document_type=proof_of_delivery`

        await axios
            .get(url, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedBillOfLadingDoc({})
                } else {
                    setUploadedBillOfLadingDoc(data[0])
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getUploadedUnloadSheetDoc = async () => {
        setIsLoading(true)
        await axios
            .get(`/api/order-documents?order_id=${params.id}&order_type=purchase&document_type=unload_sheet`, config)
            .then(res => {
                const data = res.data
                if (data.length < 1) {
                    setUploadedUnloadSheetDoc({})
                } else {
                    setUploadedUnloadSheetDoc(data[0])
                }
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const deleteDocs = async (id, name) => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_docs') + name + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,

            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }
        setIsLoading(true)
        await axios
            .delete(`/api/order-documents/${id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleFileChange = async (e, type) => {
        if (!e.target.files) {
            return;
        }
        setFile(e.target.files[0]);

        setIsLoading(true)

        const formData = new FormData()

        formData.append('file', e.target.files[0])
        formData.append('order_id', params.id)
        formData.append('order_type', 'purchase')
        formData.append('document_type', type)

        await axios
            .post(`/api/store-documents`, formData, configFile)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.message
                })
                inputRef.current.value = ""
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocation = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses/${id}`, config)
            .then((res) => {
                const data = res.data
                setPurchaseLocations((prev) => {
                    const index = prev?.findIndex((i) => i.id === id)
                    if (index < 0) {
                        const items = [...prev, data]
                        return items
                    } else {
                        return update(prev,
                            { [index]: { $set: data } }
                        )
                    }
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getPurchaseLocations = async (id) => {
        setIsLoading(true)
        await axios
            .get(`/api/purchase-addresses?vendor_id=${id}`, config)
            .then(res => {
                const data = res.data
                setPurchaseLocations(data)
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
        setPurchaseAddressId(purchaseOrder.purchase_address_id)
    }

    const updateReopenStatus = async (e) => {
        e.preventDefault()
        setDisabledFields(false)
        setStatus(3)
        setIsLoading(true)

        const formData = {}

        formData['vendor_id'] = vendor
        formData['purchase_address_id'] = purchaseAddressId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_bol_no'] = customerBOL
        formData['requested_collection_date'] = dayjs(requestedCollectionDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_collection_time'] = dayjs(requestedCollectionTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['collected_date'] = dayjs(collectedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = purchaseOrder.site_id
        formData['purchase_order_items'] = purchaseOrderItems
        formData['po_status_id'] = 3
        formData['prev_status_id'] = status
        formData['po_number'] = purchaseOrder.po_number
        formData['comments'] = comments
        formData['po_type'] = 'collection'
        formData['broker_bol_no'] = brokerBOL

        if (purchaseOrder?.type == 'STREET COLLECTION') {
            formData['forklift_driver_id'] = forklifter
            formData['unloaded_date'] = dayjs(loadedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            formData['type'] = "STREET COLLECTION"
        }

        if (purchaseOrder?.type == '3PL') {
            formData['pl_vendor'] = vendor3pl
            formData['type'] = "3PL"
        }

        await axios
            .put(`/api/purchase-orders/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setComments('')
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateReleaseStatus = async (e) => {
        setIsLoading(true)
        e.preventDefault()


        const formData = {}

        formData['vendor_id'] = vendor
        formData['purchase_address_id'] = purchaseAddressId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_bol_no'] = customerBOL
        formData['requested_collection_date'] = dayjs(requestedCollectionDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_collection_time'] = dayjs(requestedCollectionTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['collected_date'] = dayjs(collectedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = purchaseOrder.site_id
        formData['purchase_order_items'] = purchaseOrderItems
        formData['po_status_id'] = prevStatus > 3 ? prevStatus : 2
        formData['prev_status_id'] = null
        formData['po_number'] = purchaseOrder.po_number
        formData['comments'] = comments
        formData['po_type'] = 'collection'
        formData['broker_bol_no'] = brokerBOL

        if (purchaseOrder?.type == 'STREET COLLECTION') {
            formData['forklift_driver_id'] = forklifter
            formData['unloaded_date'] = dayjs(loadedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            formData['type'] = "STREET COLLECTION"
        }

        if (purchaseOrder?.type == '3PL') {
            formData['pl_vendor'] = vendor3pl
            formData['type'] = "3PL"
        }

        await axios
            .put(`/api/purchase-orders/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setDisabledFields(true)
                if (prevStatus > 3) {
                    setStatus(prevStatus)
                } else setStatus(2)
                setComments('')
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updateCompleteStatus = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['purchase_order_items'] = purchaseOrderItems

        await axios
            .put(`/api/purchase-orders/${params.id}/complete-collection`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const updatePurchaseOrder = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['vendor_id'] = vendor
        formData['purchase_address_id'] = purchaseAddressId
        formData['drop_trailer_no'] = dropTrailerNo
        formData['pickup_trailer_no'] = pickupTrailerNo
        formData['customer_bol_no'] = customerBOL
        formData['requested_collection_date'] = dayjs(requestedCollectionDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['requested_collection_time'] = dayjs(requestedCollectionTime).endOf('minute').format('YYYY-MM-DD HH:mm:ss')
        formData['collected_date'] = dayjs(collectedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        formData['site_id'] = purchaseOrder.site_id
        formData['purchase_order_items'] = purchaseOrderItems
        formData['po_status_id'] = status
        formData['po_number'] = purchaseOrder.po_number
        formData['comments'] = comments
        formData['po_type'] = 'collection'
        formData['broker_bol_no'] = brokerBOL

        if (purchaseOrder?.type == 'STREET COLLECTION') {
            formData['forklift_driver_id'] = forklifter
            formData['unloaded_date'] = dayjs(loadedDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
            formData['type'] = "STREET COLLECTION"
        }

        if (purchaseOrder?.type == '3PL') {
            formData['pl_vendor'] = vendor3pl
            formData['type'] = "3PL"
        }

        await axios
            .put(`/api/purchase-orders/${params.id}`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
                setComments('')
                /* getOrder(params.id) */
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleUpdateItemQTY = (id, quantity, qts) => {


        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.qty_ordered = toNumber(quantity)
        item.qty_to_receive = toNumber(qts)


        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const handleUpdateVendorCount = (id, quantity) => {
        const purchaseOrderItemsCopy = [...purchaseOrderItems]
        const item = purchaseOrderItemsCopy.find(item => item.id === id)

        item.vendor_count = toNumber(quantity)

        setPurchaseOrderItems(purchaseOrderItemsCopy)
    }

    const columns = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('ordered')}</span>
                </div>
            ), */
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} disabled={disabledFields} />
        },
        {
            field: 'qty_to_receive',
            headerName: t('qty_to_receive'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('to_receive')}</span>
                </div>
            ), */
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceiveCell params={params} t={t} disabled={disabledFields} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('received')}</span>
                </div>
            ), */
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedCell params={params} />
        },
        {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('vendor')}</span>
                    <span>{t('count')}</span>
                </div>
            ), */
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <VendorCountCell params={params} disabled={disabledFields} handlerVC={handleUpdateVendorCount} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('unit')}</span>
                    <span>{t('price')}</span>
                </div>
            ), */
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.7,
            minWidth: 75,
            cellClassName: 'padding-0',
            renderCell: (params) => <Actions params={params} salesOrderItems={purchaseOrderItems} setSalesOrderItems={setPurchaseOrderItems} disabled={disabledFields} />
        },
    ]

    const columns2 = [
        {
            field: 'item_no',
            headerName: t('no'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'description',
            headerName: t('description'),
            flex: 1.5,
            maxWidth: 140
        },
        {
            field: 'unit_of_measure_code',
            headerName: t('uom'),
            flex: 0.7,
            minWidth: 75
        },
        {
            field: 'qty_ordered',
            headerName: t('qty_ordered'),
           /*  renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('ordered')}</span>
                </div>
            ), */
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyOrderedCell params={params} handler={handleUpdateItem} handlerQTR={handleUpdateItemQTY} disabled={disabledFields} />
        },
        {
            field: 'qty_to_receive',
            headerName: t('qty_to_receive'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('to_receive')}</span>
                </div>
            ), */
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 100,
            renderCell: (params) => <QtyToReceiveCell params={params} t={t} disabled={disabledFields} handlerQTR={handleUpdateItemQTY} />
        },
        {
            field: 'qty_received',
            headerName: t('quantity_received'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('quantity')}</span>
                    <span>{t('received')}</span>
                </div>
            ), */
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <QtyReceivedCell params={params} />
        },
        {
            field: 'vendor_count',
            headerName: t('vendor_count'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('vendor')}</span>
                    <span>{t('count')}</span>
                </div>
            ), */
            flex: 1,
            minWidth: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <VendorCountCell params={params} disabled={disabledFields} handlerVC={handleUpdateVendorCount} />
        },
        {
            field: 'unit_price',
            headerName: t('unit_price'),
            /* renderHeader: () => (
                <div className='flex flex-col 3xl:flex-row gap-1 leading-[14px] font-medium'>
                    <span>{t('unit')}</span>
                    <span>{t('price')}</span>
                </div>
            ), */
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <UnitPriceCell params={params} />
        },
        {
            field: 'total_cost',
            headerName: t('total'),
            flex: 0.7,
            minWidth: 75,
            renderCell: (params) => <TotalCell params={params} />
        }
    ]

    const completeCollection = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['purchase_order_items'] = purchaseOrderItems

        await axios.put(`/api/purchase-orders/${params.id}/complete-from-relased`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            getPurchaseOrder(params.id)
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const setStatusToUnloading = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['po_status_id'] = 8

        await axios.put(`/api/purchase-orders/${params.id}/change-status`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            getPurchaseOrder(params.id)
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const setStatusToUnloaded = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const formData = {}

        formData['purchase_order_items'] = purchaseOrderItems

        await axios.put(`/api/purchase-orders/${params.id}/unload-collection`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            getPurchaseOrder(params.id)
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const openDgs = () => {
        navigate(`/collection/grade-sheet/${params.id}`, {
            replace: true,
        })
    }

    const retriveLoad = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        await axios.post(`/api/purchase-orders/${params.id}/retrieve-load`, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
            getPurchaseOrder(params.id)
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const undoCollection = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = {}
        formData['purchase_order_items'] = purchaseOrderItems

        await axios.put(`/api/purchase-orders/${params.id}/undo-collection-master-admin`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const markAsOnsite = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const formData = {}
        formData['purchase_order_items'] = purchaseOrderItems

        await axios.put(`/api/purchase-orders/${params.id}/mark-on-site-master-admin`, formData, config).then(({ data }) => {
            Swal.fire({
                icon: "success",
                customClass: 'success',
                showCloseButton: true,
                iconColor: '#00B78E',
                text: data.success.message
            })
        }).catch(({ response }) => {
            handleAxiosError({response: response})
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const clickGenerateBillHandler = async (e) => {
        e.preventDefault()

        if(isVendorDeleted) {
            Swal.fire({
                text: t('x_is_deleted_warning', {
                    x: t('vendor')
                }),
                icon: 'warning',
                customClass: 'warning2',
                iconColor: '#FEB806',
                reverseButtons: true,
                showDenyButton: true,
                showCloseButton: false,
                confirmButtonText: t('yes'),
                denyButtonText: t('negatory'),
                allowOutsideClick: false
            }).then((result) => {
                if(result.isConfirmed) {
                    generateBill()
                }
            })
        } else {
            generateBill()
        }
    }

    const generateBill = async (e) => {
        setIsLoading(true)

        const formData = {}

        formData['purchase_order_id'] = params.id

        await axios
            .post(`/api/quick-books/save-bill`, formData, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const optionsForklifter = forklifters?.map(option => ({ label: option.name, id: option.id }))
    const optionsVendors = listOf3PLVendors?.map(option => ({ label: option.vendor_no + ' - ' + option.name, id: option.id }))

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <Box sx={{ background: '#F5F5FD', minHeight: '100vh' }}>
                <div className='flex justify-between items-center p-5 w-full bg-white' style={{ borderRadius: '5px 5px 0 0' }}>
                    <div className='flex items-center justify-between w-full leading-none'>
                        <div className='text-[18px] font-[600] text-[#333333] flex items-center'><span className='text-[#b7472a] text-[35px] mr-4'>•</span>
                            {
                                purchaseOrder?.type == 'STREET COLLECTION' ?
                                    <p>
                                        {
                                            (
                                                (
                                                    ['master_admin', 'office_manager'].includes(user?.role) ||
                                                    userHasPermissions(['daily-board-update'])
                                                ) &&
                                            !purchaseOrder?.rejected &&
                                            purchaseOrder?.po_status_id < 10
                                            )
                                            ? t('update_sc')
                                            : t('view_sc')
                                        } - {purchaseOrder.po_number}
                                    </p>
                                    :
                                    purchaseOrder?.type == '3PL' ?
                                        <p>
                                            {
                                                (
                                                    (
                                                        ['master_admin', 'office_manager'].includes(user?.role) ||
                                                        userHasPermissions(['daily-board-update'])
                                                    ) &&
                                                !purchaseOrder?.rejected &&
                                                purchaseOrder?.po_status_id < 10
                                                )
                                                ? t('update_pl')
                                                : t('view_pl')
                                            } - {purchaseOrder.po_number}
                                        </p>
                                        :
                                        <p>
                                            {
                                                (
                                                    (
                                                        ['master_admin', 'office_manager'].includes(user?.role) ||
                                                        userHasPermissions(['daily-board-update'])
                                                    ) &&
                                                    !purchaseOrder?.rejected &&
                                                    purchaseOrder?.po_status_id < 10
                                                )
                                                ? t('update_po')
                                                : t('view_po')
                                            } - {purchaseOrder.po_number}
                                        </p>
                            }
                        </div>
                        <div style={{ transform: "rotate(45deg)" }} className="font-[600] text-[#333333]">
                            <button onClick={() => navigate((!isNil(location.state) && !isNil(location.state.prevPathname)) ? location.state.prevPathname : '/collections')}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>
                </div>

                <div className='p-5 pb-12 flex justify-between gap-3 flex-col lg:flex-row'>
                    <div className='bg-white p-6 pt-0 rounded-md w-full lg:w-2/5 h-fit edit-view'>
                        <div className='pb-11 pt-1'>
                            <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('general_information')}</p>
                        </div>
                        {/* Status */}
                        <div className={`w-full pb-4`}>
                            <p className="text-[14px] text-[#718096]">{t('status')}</p>
                            <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                {purchaseOrder.po_status_name}
                                {
                                    user.role === 'master_admin' ?
                                        purchaseOrder.po_status_id === 7 ? <Tooltip disableInteractive title={t('change_status_unloading')}><button /* disabled={disabledFields} */ onClick={e => setStatusToUnloading(e)} className='text-[#b7472a] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-truck"></i></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    user.role === 'master_admin' ?
                                        purchaseOrder.po_status_id === 8 ? <Tooltip disableInteractive title={t('change_status_unloaded')}><button /* disabled={disabledFields} */ onClick={e => setStatusToUnloaded(e)} className='text-[#b7472a] ml-5 disabled:text-zinc-400'><UnloadingTruckIcon shouldAnimate={false} size="small" /></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    user.role === 'master_admin' ?
                                        purchaseOrder.po_status_id === 4 || purchaseOrder.po_status_id === 5 ? <Tooltip disableInteractive title={t('retrive_load')}><button /* disabled={disabledFields} */ onClick={e => retriveLoad(e)} className='text-[#b7472a] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-rotate-right"></i></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    user.role === 'master_admin' ?
                                        purchaseOrder.po_status_id === 10 ? <Tooltip disableInteractive title={t('undo_collection')}><button onClick={e => undoCollection(e)} className='text-[#b7472a] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-reply"></i></button></Tooltip> : ''
                                        :
                                        ''
                                }
                                {
                                    purchaseOrder?.type != 'COLLECTION' ?
                                        null
                                        :
                                        user.role === 'master_admin' ?
                                            purchaseOrder.po_status_id === 2 ? <Tooltip disableInteractive title={t('changeToOnsite')}><button onClick={e => markAsOnsite(e)} className='text-[#b7472a] ml-5 disabled:text-zinc-400'><i className="fa-solid fa-warehouse"></i></button></Tooltip> : ''
                                            :
                                            ''
                                }

                            </div>
                        </div>

                        {/* Rejection Reasoning */}
                        {purchaseOrder?.rejected ? (
                            <div className='w-full pb-4'>
                                <p className="text-[14px] text-[#718096]">{t('reason_for_rejection')}</p>
                                <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{t(`${rejectedReason}`)}</div>
                            </div>
                        ) : (
                            ''
                        )}

                        {/* Vendor */}

                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('vendor')} *</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    type="text" disabled className='w-full' name="drop_trailer_no" value={vendorName} />
                            </div>
                        </div>
                        {/* Purchase Location */}

                        <div className='w-full pb-4'>
                            <Stack
                                direction='row'
                                gap={1}
                                alignItems='baseline'
                                justifyContent='space-between'
                            >
                                <p className="text-[14px] text-[#718096]">{t('purchaseLocation')} *</p>
                                {(purchaseAddressId && isValid(pickupInstructions)) ? (
                                    <Tooltip
                                        title={
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                {pickupInstructions}
                                            </div>
                                        }
                                    >
                                        <Stack
                                            direction='row'
                                            gap={1}
                                            alignItems='center'
                                            className='text-[#718096]'
                                        >
                                            <MenuBookIcon fontSize='18px'/>
                                            <span className='text-[14px] min-[420px]:block hidden' style={{textTransform: 'uppercase'}}>{t('pickup_instructions')}</span>
                                        </Stack>
                                    </Tooltip>
                                ) : null}
                            </Stack>

                            <div className='w-full'>
                                <FormControl sx={{ width: '100%' }}>
                                    <Select
                                        value={purchaseAddressId}
                                        onChange={(e) => { handlePurchaseLocation(e) }}
                                        disabled={disabledFields}
                                        sx={{
                                            width: '100%',
                                            background: '#FCFCFC',
                                            '.MuiOutlinedInput-input': {
                                                padding: '9px !important'
                                            },
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: '1px solid #EEEFF2 !important'
                                            }
                                        }}
                                    >
                                        {purchaseLocations?.map((item, index) => <MenuItem key={index} value={item.id}>{item.code} - {item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {
                            purchaseAddressId ?
                                <div className={`w-full p-4 address mb-4 ${disabledFields ? 'text-[#00000061]' : ''}`}>
                                    <div className='w-full'>{address}</div>
                                    {purchaseAddressId ? <div className='w-full'>{zip} {city}, {state}</div> : <div className='w-full'></div>}
                                </div>
                                :
                                null
                        }

                        {/* Drop Trailer No. */}<div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('drop_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    disabled={disabledFields} type="text" className='w-full' name="drop_trailer_no" value={dropTrailerNo} onChange={(e) => { setDropTrailerNo(e.target.value) }} />
                            </div>
                        </div>
                        {/* Pickup Trailer No. */}
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('pickup_trailer_no')}</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    disabled={disabledFields} type="text" className='w-full' name="pickup_trailer_no" value={pickupTrailerNo} onChange={(e) => { setPickupTrailerNo(e.target.value) }} />
                            </div>
                        </div>
                        {/* Customer BOL */}
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('customer_bol')}#</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    disabled={disabledFields} type="text" className='w-full' name="pickup_trailer_no" value={customerBOL} onChange={(e) => { handleCustomerPoNo(e) }} />
                            </div>
                        </div>
                        {/* Broker BOL */}
                        <div className='w-full pb-4'>
                            <p className="text-[14px] text-[#718096]">{t('broker_bol')}#</p>
                            <div className='w-full'>
                                <TextField
                                    sx={{
                                        width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                    }}
                                    disabled={disabledFields} type="text" className='w-full' name="pickup_trailer_no" value={brokerBOL} onChange={(e) => { setBrokerBOL(e.target.value) }} />
                            </div>
                        </div>

                        <div className='lg:flex gap-3 w-full'>
                            {/* Requested Delivery Date */}
                            <div className={`w-full ${!brokerTransaction && 'lg:w-1/2'} pb-4`}>
                                <p className="text-[14px] text-[#718096]">{t('requested_collection_date')}</p>
                                <div className='w-full'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileDatePicker
                                            sx={{
                                                width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                            }}
                                            disabled={disabledFields} format="YYYY-MM-DD" value={requestedCollectionDate} onChange={(newValue) => setrequestedCollectionDate(newValue)} />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            {!brokerTransaction && (
                                <div className='w-full lg:w-1/2 pb-4'>
                                    <p className="text-[14px] text-[#718096]">{t('requested_collection_time')}</p>
                                    <div className='w-full'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer sx={{ padding: '0' }} components={['TimePicker', 'TimePicker']}>
                                                <MobileTimePicker
                                                    sx={MOBILE_TIME_PICKER_STYLE}
                                                    disabled={disabledFields} value={requestedCollectionTime} onChange={(newValue) => setrequestedCollectionTime(newValue)} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            )}
                        </div>

                        {!brokerTransaction && (
                            <>
                                {/* Collected Date */}
                                {purchaseOrder?.type !== 'STREET COLLECTION' && (
                                    <div className='w-full pb-4'>
                                        <p className="text-[14px] text-[#718096]">{t('collected_date')}</p>
                                        <div className='w-full'>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker disabled={disabledFields}
                                                    sx={MOBILE_DATE_PICKER_STYLE}
                                                    format="YYYY-MM-DD" value={collectedDate} onChange={(newValue) => setcollectedDate(newValue)} />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                )}

                                {purchaseOrder?.type === 'STREET COLLECTION' ? (
                                    <>
                                        {/* Collected Date */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('collected_date')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker disabled={disabledFields}
                                                        sx={MOBILE_DATE_PICKER_STYLE}
                                                        format="YYYY-MM-DD" value={collectedDate} onChange={(newValue) => setcollectedDate(newValue)} />
                                                </LocalizationProvider>
                                            </div>
                                        </div>

                                        {/* Loaded Date */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('unloaded_date')}</p>
                                            <div className='w-full'>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker disabled={disabledFields}
                                                        sx={MOBILE_DATE_PICKER_STYLE}
                                                        format="YYYY-MM-DD" value={loadedDate} onChange={(newValue) => setLoadedDate(newValue)} />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        {/* Forklifters */}
                                        <div className='w-full pb-4'>
                                            <p className="text-[14px] text-[#718096]">{t('forklift_driver')} *</p>
                                            <div className='w-full'>
                                                <Autocomplete
                                                    disablePortal
                                                    disableClearable
                                                    disabled={disabledFields}
                                                    id="combo-box-demo"
                                                    options={optionsForklifter}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    value={{ label: forklifterName, id: forklifter }}
                                                    sx={AUTOCOMPLETE_STYLE}
                                                    onChange={(a, b) => { setForklifter(b?.id || ''); setForklifterName(b?.label) }}
                                                    renderInput={(params) => <TextField {...params} autoFocus={true} />}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {
                                            purchaseOrder.driver_name ?
                                                purchaseOrder.po_status_id > 3 || purchaseOrder.po_status_id === 3 ?
                                                    <div className='w-full pb-4'>
                                                        <p className="text-[14px] text-[#718096]">{t('driver')}</p>
                                                        <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{purchaseOrder.driver_name}</div>
                                                    </div>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                        {
                                            purchaseOrder.forklift_driver_name ?
                                                purchaseOrder.po_status_id > 6 || purchaseOrder.po_status_id === 3 ?

                                                    <div className='w-full pb-4'>
                                                        <p className="text-[14px] text-[#718096]">{t('forklift_driver')}</p>
                                                        <div className={`w-full flex justify-between status p-2 px-3 ${disabledFields ? 'text-[#00000061]' : ''}`}>{purchaseOrder.forklift_driver_name}</div>
                                                    </div>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className='w-full lg:w-3/5 h-fit'>
                        <div className='bg-white pt-0 rounded-md w-full edit-view mb-3'>
                            <div className='pt-1 pl-5'>
                                <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('purchaseOrderItems')}</p>
                            </div>
                            <div className='flex justify-end p-5 pt-0 border-b'>
                                <Tooltip disableInteractive title={t('addpurchaseOrderItems')} placement='bottom'>
                                    <div>
                                        <AddButton disabled={disabledFields} onClick={handleOpenpurchaseOrderItem}><i className="fa-solid fa-plus"></i></AddButton>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className='border-b'>
                                {
                                    purchaseOrderItems.length > 0 ?
                                        (
                                            (status == 1 || status == 3)
                                            && (
                                                ['master_admin', 'office_manager'].includes(user?.role)
                                                || userHasPermissions(['daily-board-update'])
                                            )
                                        ) ?
                                            <div className='flex justify-end w-full p-2'>
                                                <button className='px-2 py-1 text-white bg-red-600 font-bold rounded-md mb-1 roboto text-xs uppercase' onClick={e => setPurchaseOrderItems([])}>{t('clear_all')}</button>
                                            </div>
                                            :
                                            null
                                        :
                                        null
                                }
                                <TableShared columns={disabledFields ? columns2 : columns} items={purchaseOrderItems} disableColumnMenu={true} />
                            </div>

                            <div className='flex justify-end w-full p-5'>
                                <div className='w-full lg:w-1/3'>
                                    <div className='flex justify-between text-[#333333] text-[15px] pb-4'>
                                        <p>{t('totalPrice')}</p>
                                        <p className='font-[500]'>{currencySymbol}{totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    <div className='flex justify-between text-[#333333] text-[15px]'>
                                        <p>{t('totalQty')}</p>
                                        <p className='font-[500]'>{totalQty}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full block gap-3 lg:flex'>
                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2 h-fit'>
                                <div className='pt-1 p-5 flex justify-between items-start'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('comments')}</p>
                                    <CommentHistoryButton
                                        badgeContent={commentHistory?.length ?? ''}
                                        backgroundColor='#b7472a'
                                        onClick={() => setOpenCommentHistory(true)}
                                    />
                                </div>
                                <div className='w-full p-5 pt-0'>
                                    <p className="text-[14px] text-[#718096]">{t('comments_text')}</p>
                                    <TextField
                                        multiline
                                        rows={3}
                                        value={comments}
                                        disabled={disabledFields}
                                        onChange={e => setComments(e.target.value)}
                                        sx={{
                                            width: '100%', background: '#FCFCFC', '.MuiOutlinedInput-input': { padding: '9px !important' },
                                            boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '1px solid #EEEFF2 !important' }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='bg-white pt-0 rounded-md edit-view mb-3 w-full lg:w-1/2'>
                                <div className='pt-1 p-5'>
                                    <p className='text-[13px] font-[600] uppercase text-[#A1ACB8]'>{t('documents')}</p>
                                </div>
                                <div className='w-full'>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="document tab list" variant='scrollable'>
                                                <Tab sx={{ textTransform: 'none' }} value="1" label={t('purchase_documents')} />
                                                <Tab sx={{ textTransform: 'none' }} value="2" label={t('bill_of_lading')} />
                                                <Tab sx={{ textTransform: 'none' }} value="3" label={t('unload_sheet')} />
                                            </TabList>
                                        </Box>
                                        {/* ORDER DOCUMENTS */}
                                        <TabPanel value="1">
                                            <div className='w-full text-center'>
                                                <Button onClick={handleUploadClick} disabled={disabledFields} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                    <div className='flex items-center gap-2'><p>{t('upload')}</p> <i className="fa-solid fa-cloud-arrow-up"></i></div>
                                                </Button>
                                                <input hidden type="file" name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'order_document')} />
                                            </div>
                                            <>
                                                {
                                                    orderDocuments?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><Tooltip disableInteractive title={t('view')} placement='bottom'><button className='text-neutral-500'><i className="fa-solid fa-eye"></i></button></Tooltip></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                    ) ? (
                                                                        <button disabled={disabledFields} className={`text-neutral-500 ${disabledFields ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {hasDocs ? (
                                                    <div className='text-sm text-slate-600 pt-5'>{t('driver_documents')}</div>
                                                ) : (
                                                    ''
                                                )}
                                                {
                                                    driverDocs?.map((item, index) => (

                                                        <div className='pt-3 flex justify-between gap-2' key={index}>
                                                            <p className='text-blue-900 underline line-clamp-3' ><a href={item.document_url} target="_blank" download>{item.document_name}</a></p>
                                                            <div className='flex gap-3 items-center'>
                                                                <a href={item.document_url} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view')} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                                {
                                                                    (
                                                                        ['master_admin', 'office_manager'].includes(user?.role)
                                                                        || userHasPermissions(['daily-board-update'])
                                                                    ) ? (
                                                                        <button disabled={disabledFields} className={`text-neutral-500 ${disabledFields ? 'hidden' : 'block'}`} onClick={e => deleteDocs(item.id, item.document_name)}><Tooltip disableInteractive title={t('delete')} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                    ) : (
                                                                        ''
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        </TabPanel>

                                        {/* PROOFS OF DELIVERY */}
                                        <TabPanel value="2">

                                            {/* Auto-generated Bills of Lading */}

                                            {(Object.keys(uploadedBillOfLadingDoc).length <= 0 && !driverBolDoc) && (
                                                <>
                                                    <div className='pt-5 flex justify-between'>
                                                        <p className='text-blue-900 underline'><a
                                                            href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/proof-of-delivery?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                            target="_blank"><span className="text-[#336195]"
                                                                style={{ cursor: 'pointer' }}>{t('bill_of_lading')}</span></a>
                                                        </p>
                                                        <div className='flex items-center gap-3'>
                                                            {/* <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/proof-of-delivery`} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive  title={t('view_x', { x: t('BoL') })} placement='top'><i className="fa-solid fa-eye"></i></Tooltip></button></a> */}
                                                            <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/proof-of-delivery?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                                target="_blank" download>
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-eye"></i></Tooltip></button>
                                                            </a>
                                                            <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/proof-of-delivery?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`}
                                                                target="_blank" download>
                                                                <button className='text-neutral-500'><Tooltip disableInteractive
                                                                    title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                        className="fa-solid fa-download"></i></Tooltip></button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {
                                                        purchaseOrder?.type == '3PL' ?
                                                            <div className='pt-5 flex justify-between'>
                                                                <p className='text-blue-900 underline'><a
                                                                    href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                                    target="_blank"><span className="text-[#336195]"
                                                                        style={{ cursor: 'pointer' }}>3PL {t('bill_of_lading')}</span></a>
                                                                </p>
                                                                <div className='flex items-center gap-3'>
                                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`}
                                                                        target="_blank" download>
                                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                                            title={t('view_x', { x: t('BoL') })} placement='bottom'><i
                                                                                className="fa-solid fa-eye"></i></Tooltip></button>
                                                                    </a>
                                                                    <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/3pl-pod?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`}
                                                                        target="_blank" download>
                                                                        <button className='text-neutral-500'><Tooltip disableInteractive
                                                                            title={t('download_x', { x: t('BoL') })} placement='bottom'><i
                                                                                className="fa-solid fa-download"></i></Tooltip></button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </>
                                            )}

                                            {/* Manually-uploaded Bills of Lading */}

                                            {Object.keys(uploadedBillOfLadingDoc).length > 0 ? (
                                                <>
                                                    <div className='text-sm text-slate-600 pt-5'>{t('uploaded_x', { x: t('bill_of_lading') })}</div>

                                                    <div className='pt-1 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedBillOfLadingDoc.document_url} target="_blank" download>{uploadedBillOfLadingDoc.document_name}</a></p>
                                                        <div className='flex gap-2 items-center'>
                                                            <a
                                                                href={uploadedBillOfLadingDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            {
                                                                (
                                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                                    || userHasPermissions(['daily-board-update'])
                                                                ) ? (
                                                                    <button disabled={disabledFields} className={`text-neutral-500 ${disabledFields ? 'hidden' : 'block'}`} onClick={e => deleteDocs(uploadedBillOfLadingDoc.id, uploadedBillOfLadingDoc.document_name)}><Tooltip disableInteractive title={t('delete_x', { x: t('BoL') })} placement='bottom'><i className="fa-solid fa-trash"></i></Tooltip></button>
                                                                ) : (
                                                                    ''
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className={`pt-5 w-full text-center`}>
                                                    <Button onClick={handleUploadClick} disabled={disabledFields} variant="contained" component="label" sx={{ backgroundColor: '#607d8b', "&:hover": { backgroundColor: '#6c8794' } }}>
                                                        <div className='flex items-center gap-3'><i className="fa-solid fa-cloud-arrow-up"></i> <p>{t('upload_x', { x: t('BoL') })}</p> </div>
                                                    </Button>
                                                    <input hidden type="file" accept='.pdf' name="fileUpload" ref={inputRef} onChange={e => handleFileChange(e, 'proof_of_delivery')} />
                                                </div>
                                            )}

                                            {driverBolDoc ? (
                                                <>
                                                    <div className='text-sm text-slate-600 pt-5'>{t('driver_bill_of_lading')}</div>

                                                    <div className='pt-3 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3' ><a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download>{driverBolDoc?.document_name}</a></p>
                                                        <div className='flex items-center gap-3'>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                            <a href={driverBolDoc?.document_url} target="_blank" rel='noreferrer' download><button className='text-neutral-500'><Tooltip disableInteractive title={t('download_x', {x: t('BoL')})} placement='bottom'><i className="fa-solid fa-download"></i></Tooltip></button></a>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </TabPanel>

                                        <TabPanel value="3">
                                            {Object.keys(uploadedUnloadSheetDoc).length > 0 ? (
                                                <>
                                                    <div className='text-sm text-slate-600'>{t('uploaded_x', { x: t('unload_sheet') })}</div>

                                                    <div className='pt-1 flex justify-between gap-2'>
                                                        <p className='text-blue-900 underline line-clamp-3'><a href={uploadedUnloadSheetDoc.document_url} target="_blank" download>{uploadedUnloadSheetDoc.document_name}</a></p>
                                                        <div className='flex gap-3 items-center'>
                                                            <a
                                                                href={uploadedUnloadSheetDoc.document_url}
                                                                target="_blank"
                                                                download
                                                            ><button className='text-neutral-500'><Tooltip disableInteractive title={t('view_x', { x: t('unload_sheet') })} placement='bottom'><i className="fa-solid fa-eye"></i></Tooltip></button></a>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='pt-5 flex justify-between'>
                                                    <p className='text-blue-900 underline' ><a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/unload-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=view`} target="_blank"><span className="text-[#336195]" style={{ cursor: 'pointer' }}>{t('unload_sheet')}</span></a></p>
                                                    <div className='flex items-center gap-2'>
                                                        <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${params.id}/unload-sheet?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}&stream=download`} target="_blank" download><button className='text-neutral-500'><Tooltip disableInteractive title={t('download_x', { x: t('unload_sheet') })} placement='bottom'><i className="fa-solid fa-download"></i></Tooltip></button></a>

                                                        {
                                                            (
                                                                status === 9
                                                                && (
                                                                    ['master_admin', 'office_manager'].includes(user?.role)
                                                                    || userHasPermissions(['daily-board-update'])
                                                                )
                                                            ) ? (
                                                                <button className='text-neutral-500' onClick={openDgs}>
                                                                    <Tooltip disableInteractive title={t('edit_dgs')} placement='bottom'>
                                                                        <i className="fa-solid fa-file-pen"></i>
                                                                    </Tooltip>
                                                                </button>
                                                            ) : null
                                                        }

                                                    </div>
                                                </div>
                                            )}
                                        </TabPanel>
                                    </TabContext>
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-end gap-3'>
                            {
                                (
                                    (
                                        ['master_admin', 'office_manager'].includes(user?.role) ||
                                        userHasPermissions(['daily-board-update'])
                                    ) &&
                                    !purchaseOrder?.rejected
                                ) ? (
                                    <>

                                        {
                                            purchaseOrder?.type == "COLLECTION" ?
                                                <PurchaseOrderButtons status={status} update={updatePurchaseOrder} release={updateReleaseStatus} reopen={updateReopenStatus} generateBill={clickGenerateBillHandler} disabled={disabledFields} complete={updateCompleteStatus} />
                                                :
                                                null
                                        }
                                        {
                                            purchaseOrder?.type == "STREET COLLECTION" ?
                                                <StreetCollectionButtons status={status} update={updatePurchaseOrder} release={updateReleaseStatus} reopen={updateReopenStatus} generateBill={clickGenerateBillHandler} disabled={disabledFields} complete={completeCollection} />
                                                :
                                                null
                                        }

                                    </>
                                ) : (
                                    <div className='px-5 py-9 text-[#336195] max-[560px]:text-xs max-[560px]:py-3 max-[560px]:px-2'></div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Box>
            <AddPurchaseOrderItem open={openPurchaseOrderItem} handleOpen={handleOpenpurchaseOrderItem} purchaseOrderItems={purchaseOrderItems} setPurchaseOrderItems={setPurchaseOrderItems} disabled={disabled} setDisabled={setDisabled} />
            <CommentHistoryModal
                open={openCommentHistory}
                setOpen={setOpenCommentHistory}
                comments={commentHistory}
            />
        </>
    )
}

export default EditPurchaseOrder

const maxQtyDigits = 5

const QtyOrderedCell = ({ params, handler, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    const handleZeroLocal = (e) => {
        if (e.target.value === "" || e.target.value === '-') {
            setQty(0)

            handler(
                params.row.id,
                0,
                params.row.unit_price
            )
        }
    }

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value < params.row.qty_to_receive) {
                    setQty(e.target.value)

                    handlerQTR(
                        params.row.id,
                        e.target.value,
                        params.row.qty_to_receive
                    )
                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                } else {
                    setQty(e.target.value)

                    handler(
                        params.row.id,
                        e.target.value,
                        params.row.unit_price
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            disabled={disabled}
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={handleZeroLocal}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )

}

const QtyToReceiveCell = ({ params, t, disabled, handlerQTR }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_ordered, params.row.qty_to_receive])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            } else {
                if (e.target.value > params.row.qty_ordered) {
                    setQty(params.row.qty_ordered)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        params.row.qty_ordered
                    )
                    Swal.fire({
                        icon: "warning",
                        text: t('warningPurchaseOrder'),
                        customClass: 'warning2',
                        showCloseButton: true,
                        iconColor: '#FFCA28'
                    })
                } else {
                    setQty(e.target.value)
                    handlerQTR(
                        params.row.id,
                        params.row.qty_ordered,
                        e.target.value
                    )
                }
            }
        }
    }

    return (
        <TextField
            type="text"
            disabled={disabled}
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-to-receive"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
        />
    )

}

const QtyReceivedCell = ({ params }) => {
    const [qty, setQty] = useState(params.value)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.qty_received])

    return (
        <TextField
            type="text"
            variant='standard'
            className='w-2/3 mb-5 px-0 pt-0'
            name="qty-received"
            value={qty}
            onChange={(e) => handleInteger({
                event: e,
                setter: setQty,
                end: 30
            }) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                value: qty
            })}
            disabled
        />
    )
}

const VendorCountCell = ({ params, disabled, handlerVC }) => {
    const [qty, setQty] = useState(params.value || 0)

    useEffect(() => {
        setQty(params.value)
    }, [params.row.vendor_count])

    const handleQuantity = (e) => {
        const regex = createRegexFromString(`/^(-?\\d{0,${maxQtyDigits}})$/i`)
        if (regex.test(e.target.value)) {
            if (e.target.value < 0 || e.target.value === '-') {
                setQty(0)
            }
            else {
                setQty(e.target.value)
                handlerVC(
                    params.row.id,
                    e.target.value
                )
            }
        }
    }

    return (
        <TextField
            type="text"
            variant='standard'
            disabled={disabled}
            className='w-2/3 mb-5 px-0 pt-0'
            name="vendor-count"
            value={qty}
            onChange={(e) => handleQuantity(e) }
            onBlur={e => handleZero({
                event: e,
                setter: setQty
            })}
            onFocus={e => deleteZero({
                event: e,
                setter: setQty,
                qty
            })}
        />
    )
}

const UnitPriceCell = (params) => {
    const { currencySymbol } = useStateContext()

    return (
        <>{currencySymbol}{params.params.row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )
}

const TotalCell = (params) => {

    const [total, setTotal] = useState(params.params.value)
    const { currencySymbol } = useStateContext()

    useEffect(() => {
        setTotal(params.params.row.qty_ordered * params.params.row.unit_price)
    }, [params])

    return (
        <>{currencySymbol}{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
    )

}

const Actions = ({ params, salesOrderItems, setSalesOrderItems, disabled }) => {
    const { t } = useTranslation()

    const deleteItem = async (id) => {
        const filtered = salesOrderItems.filter(i => i.id != id)
        setSalesOrderItems(filtered)
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={() => deleteItem(params.row.id)}><i className="fa-solid fa-trash"></i></span>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
