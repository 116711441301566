import React, { useState, useEffect, useMemo } from 'react'

import { Tooltip, Chip, Box, Checkbox, ListItemText, ListItemIcon, LinearProgress } from '@mui/material'
import ClearIcon from "@mui/icons-material/Clear"
import IconButton from "@mui/material/IconButton"
import InputLabel from '@mui/material/InputLabel'
import { useTranslation } from "react-i18next"
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { Link, useSearchParams } from 'react-router-dom'
import update from 'immutability-helper'
import { useDebounce } from "use-debounce"
import { includes, isEqual } from 'lodash'

import AppLayout from '../../components/Layouts/AppLayout'
import Loading from '../../components/Loading'
import { useStateContext } from '../../context/ContextProvider'
import axios from '../../lib/axios'
import ServerTable from '../ServerTable'
import { useAuth } from '../../hooks/auth'
import brokerTransactionStatusAPI from '../../services/api/BrokerTransactions/brokerTransactionStatusAPI'
import { buildUrl } from '../../helpers/apiHelper'
import { handleAxiosError } from '../../helpers/helper'

const Collections = () => {

    const { t } = useTranslation()
    const { choosesite, setChoosesite, config, pusher, logisticsTypes } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })
    const [searchParams, setSearchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(false)
    const [purchaseOrders, setPurchaseOrders] = useState([])
    const [salesOrderDates, setSalesOrderDates] = useState([])
    const [searchStatuses, setSearchStatuses] = useState([])

    const [openItems, setOpenItems] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    /* filters */
    const [soNumber, setSoNumber] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_no || ""
        } else {
            return ""
        }
    })
    const [customerNo, setCustomerNo] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_customer_no || ""
        } else {
            return ""
        }
    })
    const [customerName, setCustomerName] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_customer_name || ""
        } else {
            return ""
        }
    })
    const [type, setType] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_type || ""
        } else {
            return ""
        }
    })
    const [searchStatus, setsearchStatus] = useState(() => {
        if (!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_status || []
        } else {
            return []
        }
    })
    const [searchStatusId, setsearchStatusId] = useState(() => {
        if (!searchParams.has('get') && localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_status_id || []
        } else {
            return []
        }
    })
    const [searchDate, setsearchDate] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_requested_delivery_date || ""
        } else {
            return ""
        }
    })
    const [logisticsTypeId, setLogisticsTypeId] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.collctions_logistics_type || ""
        } else {
            return ""
        }
    })

    const [debouncedValue] = useDebounce(soNumber, 500);
    const [debouncedValue1] = useDebounce(customerNo, 500);
    const [debouncedValue2] = useDebounce(customerName, 500);
    const [random, setRandom] = useState(null)

    const isAllSelectedShipTo = searchStatuses.length > 0 && searchStatus.length === searchStatuses.length;

    const [model, setModel] = useState({
        actions: true,
        driver_name: false,
        forklift_driver_name: false,
        po_number: true,
        po_status_name: true,
        purchase_address_name: false,
        requested_collection_date: true,
        type: true,
        vendor_name: true,
        vendor_no: true
    })

    useEffect(() => {
        setChoosesite(localStorage.getItem('site'))
        getTableView()
    }, [])

    const getTableView = async () => {
        setIsLoading(true)
        await axios.get(`/api/get-table-view?view_name=collections_table&user_id=${user?.id}`, config)
            .then(res => {
                const model = res.data
                if (Object.keys(model?.success?.data).length > 0) {
                    setModel(model.success.data)
                }
                setIsLoading(false)
            })
            .catch(({ response }) => {
                console.log(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {

        const channeldelete = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
        const channelcreate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${localStorage.getItem('site')}`)
        const channelupdate = pusher.subscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)

        if (choosesite) {
            channeldelete.bind(`${localStorage.getItem('client_id')}-purchaseorder-deleted-event-site-${localStorage.getItem('site')}`, data => {
                setRandom(Math.random())
            })

            channelcreate.bind(`${localStorage.getItem('client_id')}-purchaseorder-created-event-site-${localStorage.getItem('site')}`, data => {
                setRandom(Math.random())
            })

            channelupdate.bind(`${localStorage.getItem('client_id')}-purchaseorder-updated-event-site-${localStorage.getItem('site')}`, data => {
                setRandom(Math.random())
            })
        }

        setChoosesite(localStorage.getItem('site'))

        return (() => {
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-deleted-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-updated-site-${localStorage.getItem('site')}`)
            pusher.unsubscribe(`${localStorage.getItem('client_id')}-purchaseorder-created-site-${localStorage.getItem('site')}`)
        })
    }, [choosesite])


    useEffect(() => {
        getStatuses()
    }, [])

    useEffect(() => {
        if (choosesite) {
            getPurchaseOrders()
        }
        createFilterArray(soNumber, customerNo, customerName, searchDate, searchStatus, type, logisticsTypeId, searchStatusId)
    }, [choosesite, searchDate, searchStatus, type, logisticsTypeId, page, pageSize, debouncedValue, debouncedValue1, debouncedValue2, random])


    const getPurchaseOrders = async () => {
        setIsLoading(true)
        let date
        if (searchDate == '') {
            date = ''
        } else {
            date = dayjs(searchDate).format('YYYY-MM-DD')
        }

        let url = buildUrl(
            '/api/list-orders',
            {
                where: {
                    order_type: 'purchase',
                    site_id: choosesite,
                    vendor_no: customerNo,
                    vendor_name: customerName,
                    po_number: soNumber,
                    requested_collection_date: date,
                    page: page + 1,
                    page_size: pageSize
                }
            }
        )

        if(user?.role === 'vendor_user') {
            url = url.concat(
                `&bt_status_id=${searchStatusId}`,
                '&include_broker=true',
                `&logistics_type_id=${logisticsTypeId}`
            );
        } else {
            url = url.concat(
                `&status_id=${searchStatusId}`,
                '&rejected=true',
                `&type=${type}`
            )
        }

        await axios
            .get(url, config)
            .then(res => {
                const purchaseOrders = res.data?.data
                const count = res.data?.total
                setPurchaseOrders(purchaseOrders)
                getPurchaseOrderDates(purchaseOrders)
                setTotal(count)
            })
            .catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleSearchNo = (e) => {
        setSoNumber(e.target.value)
    }

    const handleCustomerhNo = (e) => {
        setCustomerNo(e.target.value)
    }

    const handleCustomerName = (e) => {
        setCustomerName(e.target.value)
    }


    const getPurchaseOrderDates = (salesOrders) => {

        let dates = [];

        salesOrders.forEach(order => {
            let newFutureDate = order.requested_collection_date;

            let newFutureDateExists = dates.includes(newFutureDate);

            if (!newFutureDateExists) {
                dates.push(newFutureDate);
            }
        });

        dates.sort();

        setSalesOrderDates(dates);
    }

    const getStatuses = async () => {
        if(user?.role != 'vendor_user') {
            getPurchaseOrderStatuses()
        } else {
            getBrokerTransactionStatuses()
        }
    }

    const getBrokerTransactionStatuses = async () => {
        await brokerTransactionStatusAPI
            .getBrokerTransactionStatuses()
            .then((data) => {
                const statuses = data
                    .map((status) => ({
                        id: status.id,
                        po_status: status.bt_status,
                        po_name: status.bt_name,
                        po_description: status.bt_description
                    }))
                    .filter((status) => status.id != 1)
                setSearchStatuses(statuses)
            })
            .catch(({ response }) => {
                console.error(response)
            })
    }

    const getPurchaseOrderStatuses = async () => {
        await axios
            .get(`api/purchase-order-statuses`, config)
            .then(res => {
                const statuses = res.data
                setSearchStatuses(statuses)

                if (statuses.length > 0 && searchParams.has('get')) {
                    let objects = []
                    let ids = []

                    switch (searchParams.get('get')) {
                        case 'active':
                            ids = [1, 2, 3, 4, 5, 6, 7, 8, 9]
                            objects = statuses.filter((status) => includes(ids, status.id))
                            break
                        case 'inactive':
                            ids = [10, 11, 12]
                            objects = statuses.filter((status) => includes(ids, status.id))
                            break
                        default:
                            ids = []
                            objects = []
                    }

                    setsearchStatusId(ids)
                    setsearchStatus(objects)
                }
            })
            .catch(({ response }) => {
                console.error(response)
            })
    }

    const handleClearDate = () => {
        setsearchDate('')

        getPurchaseOrderDates(purchaseOrders)
    }


    const handleClearType = () => {
        setType('')
    }

    const handleClearLogisticsTypeId = () => {
        setLogisticsTypeId('')
    }

    const assembleColumns = () => {
        const columns = []

        const poNumberColumn = {
            field: 'po_number',
            headerName: t('purchase_order_no'),
            flex: 0.7,
            renderCell: (params) => <SoNumber params={params} />,
            minWidth: 200,
            cellClassName: 'relative'
        }

        const vendorNoColumn = {
            field: 'vendor_no',
            headerName: t('vendor_no'),
            flex: 0.7,
            minWidth: 200
        }

        const vendorNameColumn = {
            field: 'vendor_name',
            headerName: t('vendor_name'),
            flex: 1,
            minWidth: 200
        }

        const forkliftDriverNameColumn = {
            field: 'forklift_driver_name',
            headerName: t('forklift_driver'),
            flex: 1,
            minWidth: 200,
            hide: true
        }

        const driverNameColumn = {
            field: 'driver_name',
            headerName: t('driver'),
            valueGetter: (value, row) => {
                return value?.row?.special_driver_name ?? value?.row?.driver_name
            },
            flex: 1,
            minWidth: 200,
            hide: true
        }

        const purchaseAddressNameColumn= {
            field: 'purchase_address_name',
            headerName: t('purchase_add'),
            flex: 1,
            minWidth: 200,
            hide: true
        }

        const requestedCollectionDateColumn = {
            field: 'requested_collection_date',
            headerName: t('requested_collection_date'),
            flex: 0.7,
            renderCell: (params) => <ShowDate params={params} />,
            minWidth: 200
        }

        const poStatusNameColumn = {
            field: 'po_status_name',
            headerName: t('status'),
            flex: 0.7,
            renderCell: (params) => <Status params={params} />,
            minWidth: 200
        }

        const typeColumn = user?.role == 'vendor_user' ? {
            field: 'type',
            headerName: t('logistics_type'),
            valueGetter: (value, row) => {
                return t(value?.row?.broker_transaction?.logistics_type_key) ?? ''
            },
            flex: 0.7,
            minWidth: 200
        } : {
            field: 'type',
            headerName: t('type'),
            flex: 0.7,
            renderCell: (params) => {
                return (
                    <div>{params?.row?.type === 'COLLECTION' ? t('standard') : t('street_collection')}</div>
                )
            },
            minWidth: 200
        }

        const actionsColumn = {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            flex: 0.5,
            cellClassName: 'padding-0',
            renderCell: (params) => <PurchaseOrderAction row={params?.row} user={user} config={config} params={params} setIsLoading={setIsLoading} getPurchaseOrders={getPurchaseOrders} />,
            minWidth: 200
        }

        user?.role == 'vendor_user'
            ? columns.push(
                poNumberColumn,
                vendorNoColumn,
                vendorNameColumn,
                driverNameColumn,
                purchaseAddressNameColumn,
                requestedCollectionDateColumn,
                poStatusNameColumn,
                typeColumn,
                actionsColumn
            )
            : columns.push(
                poNumberColumn,
                vendorNoColumn,
                vendorNameColumn,
                forkliftDriverNameColumn,
                driverNameColumn,
                purchaseAddressNameColumn,
                requestedCollectionDateColumn,
                poStatusNameColumn,
                typeColumn,
                actionsColumn
            )

        return columns
    }

    const columns = assembleColumns()

    const handleItems = (event) => {
        const { target: { value } } = event

        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
            } else {
                duplicateRemoved.push(item);
            }
        })

        let ids = []

        value.forEach((item) => {
            if (ids.findIndex((o) => o.id === item.id) >= 0) {
                ids = ids.filter((x) => x.id === item.id);
            } else {
                ids.push(item.id);
            }
        })


        if (value[value.length - 1] === "all") {
            setsearchStatus(searchStatus.length === searchStatuses.length ? [] : searchStatuses)
            setsearchStatusId(searchStatus.length === searchStatuses.length ? [] : searchStatuses.map(i => i.id))
            return;
        }

        searchParams.delete('get')
        setSearchParams(searchParams)
        setsearchStatus(duplicateRemoved)
        setsearchStatusId(ids)
    }

    const handleClearItem = () => {
        searchParams.delete('get')
        setSearchParams(searchParams)
        setsearchStatus([])
        setsearchStatusId([])
    }

    const handleDeleteItem = (e, id) => {
        const shouldDelete = searchStatus.find((x) => x.id === id)
        let shipToLocationId = []
        if (shouldDelete) {
            const filtered = searchStatus.filter((x) => x.id !== shouldDelete.id)
            searchParams.delete('get')
            setSearchParams(searchParams)
            if (filtered.length > 0) {
                filtered.forEach((stl) => {
                    if (stl.hasOwnProperty('id')) searchStatusId.push(stl.id)
                })
                shipToLocationId = filtered.map((filter) => filter.id)
                setsearchStatus(filtered)
                setsearchStatusId(shipToLocationId)
            } else {
                setsearchStatus([])
                setsearchStatusId([])
                setOpenItems(false)
            }
        }
    }

    const createFilterArray = (collctionsNo, collctionsCustomerNo, collctionsCustomerName, collctionsRequestedDeliveryDate, collctionsStatus, collctionsType, collctionsLogisticsType, collctionsStatusId) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].collctions_no = collctionsNo
        filters[0].collctions_customer_no = collctionsCustomerNo
        filters[0].collctions_customer_name = collctionsCustomerName
        filters[0].collctions_requested_delivery_date = collctionsRequestedDeliveryDate
        filters[0].collctions_status = collctionsStatus
        filters[0].collctions_type = collctionsType
        filters[0].collctions_logistics_type = collctionsLogisticsType
        filters[0].collctions_status_id = collctionsStatusId

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='flex justify-between'>
                    <div className='p-5 pt-0 w-full'>
                        <div className='pb-5 bg-white mb-2 rounded-md'>
                            <div className='border-b pb-5'>
                                <div className='flex justify-between items-end w-full'>
                                    <div className='px-5 pt-5 w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input type="text" placeholder={t('search_by_purchase_no')} value={soNumber} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleSearchNo} />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>
                                    <div className=' pt-5 w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input type="text" placeholder={t('search_by_vendor_no')} value={customerNo} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleCustomerhNo} />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>
                                    <div className='px-5 pt-5 w-full'>
                                        <div className='flex justify-between items-center search'>
                                            <input type="text" placeholder={t('search_by_vendor_name')} value={customerName} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={handleCustomerName} />
                                            <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between items-end w-full'>
                                    <div className='px-5 pt-5 w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="demo-simple-select-standard-label">{t('search_by_request_collection_date')}</InputLabel>
                                            <Select
                                                value={searchDate}
                                                onChange={e => setsearchDate(e.target.value)}
                                                label="Search Past Or Present Date"
                                                sx={{
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": { display: searchDate ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                                }}
                                                endAdornment={searchDate ? (<IconButton sx={{ visibility: searchDate ? "visible" : "hidden", padding: '0' }} onClick={handleClearDate}><ClearIcon /></IconButton>) : false}
                                            >
                                                {
                                                    salesOrderDates.length < 1 ?
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>
                                                        :
                                                        salesOrderDates.map((date) => (
                                                            <MenuItem key={date} value={date}>{date}</MenuItem>
                                                        ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className='pt-5 w-full'>
                                        <FormControl sx={{ width: 'inherit' }}>
                                            <InputLabel id="demo-simple-select-standard-label">{t('search_by_status')}</InputLabel>
                                            <Select
                                                multiple
                                                value={searchStatus}
                                                open={openItems}
                                                onOpen={() => setOpenItems(true)}
                                                onClose={() => setOpenItems(false)}
                                                onChange={handleItems}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((x) => (
                                                            <Chip
                                                                key={`i_option-${x.id}`}
                                                                label={x.po_name}
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                onDelete={(e) => handleDeleteItem(e, x.id)}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                                sx={{
                                                    boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                    ".MuiSelect-iconOutlined": { display: (searchStatus.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                                }}
                                                endAdornment={searchStatus ? (<IconButton sx={{ visibility: (searchStatus.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={handleClearItem}><ClearIcon /></IconButton>) : false}
                                            >
                                                {
                                                    searchStatuses?.length > 0 ?
                                                        <MenuItem value="all">
                                                            <ListItemIcon>
                                                                <Checkbox checked={isAllSelectedShipTo} indeterminate={searchStatus.length > 0 && searchStatus.length < searchStatuses.length} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={t('select_all')} />
                                                        </MenuItem>
                                                        :
                                                        <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                                }
                                                {
                                                    searchStatuses.map((it) =>
                                                        <MenuItem value={it} key={it.id}>
                                                            <Checkbox checked={searchStatus?.findIndex((i) => i.id === it.id) >= 0} />
                                                            <ListItemText primary={it.po_name} />
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {user?.role != 'vendor_user' ? (
                                        <div className='px-5 pt-5 w-full'>
                                            <FormControl sx={{ width: 'inherit' }}>
                                                <InputLabel id="demo-simple-select-standard-label">{t('search_by_type')}</InputLabel>
                                                <Select
                                                    value={type}
                                                    onChange={e => setType(e.target.value)}
                                                    sx={{
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important'
                                                        },
                                                        background: '#F5F5F5',
                                                        borderRadius: '6px',
                                                        ".MuiSelect-iconOutlined": {
                                                            display: type ? 'none !important' : ''
                                                        },
                                                        "&.Mui-focused .MuiIconButton-root": {
                                                            color: 'rgba(0,0,0,.42)'
                                                        }
                                                    }}
                                                    endAdornment={type ? (<IconButton sx={{ visibility: type ? "visible" : "hidden", padding: '0' }} onClick={handleClearType}><ClearIcon /></IconButton>) : false}
                                                >
                                                    <MenuItem value={'COLLECTION'}>{t('standard')}</MenuItem>
                                                    <MenuItem className='pickup' value={'STREET COLLECTION'}>{t('street_collection')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    ) : (
                                        <div className='px-5 pt-5 w-full'>
                                            <FormControl sx={{ width: 'inherit' }}>
                                                <InputLabel id="demo-simple-select-standard-label">{t('search_by_x', {x: t('logistics_type')})}</InputLabel>
                                                <Select
                                                    value={logisticsTypeId}
                                                    onChange={e => setLogisticsTypeId(e.target.value)}
                                                    sx={{
                                                        boxShadow: 'none',
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            border: 'none !important'
                                                        },
                                                        background: '#F5F5F5',
                                                        borderRadius: '6px',
                                                        ".MuiSelect-iconOutlined": {
                                                            display: logisticsTypeId ? 'none !important' : ''
                                                        },
                                                        "&.Mui-focused .MuiIconButton-root": {
                                                            color: 'rgba(0,0,0,.42)'
                                                        }
                                                    }}
                                                    endAdornment={logisticsTypeId ? (<IconButton sx={{ visibility: logisticsTypeId ? "visible" : "hidden", padding: '0' }} onClick={handleClearLogisticsTypeId}><ClearIcon /></IconButton>) : false}
                                                >
                                                    {logisticsTypes?.map((logisticsType, index) => (
                                                        <MenuItem key={`logtype-${index}`} value={logisticsType.id}>{t(logisticsType.key)}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='pt-3'>
                                <ServerTable columns={columns} items={purchaseOrders} total={total} setPage={setPage} setPageSize={setPageSize} page={page} pageSize={pageSize} columnVisibilityModel={model} setModel={setModel} view='collections' />
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default Collections

const Status = ({ params }) => {
    const { row } = params

    if(row?.type === 'BROKER') {
        return row.broker_transaction?.bt_status_name ?? row.po_status_name
    } else {
        return (
            <>
                {
                    row.po_status_id == 7 ?
                    row.forklift_driver_id ?
                            <Tooltip disableInteractive title={row.forklift_driver_name} placement='bottom'>
                                <div>{row.po_status_name}</div>
                            </Tooltip>
                            :
                            <>{row.po_status_name}</>
                        :
                        row.po_status_id == 4 ?
                            row.driver_id ?
                                <Tooltip disableInteractive title={row.driver_name} placement='bottom'>
                                    <div>{row.po_status_name}</div>
                                </Tooltip>
                                :
                                <>{row.po_status_name}</>
                            :
                            <>{row.po_status_name}</>
                }

            </>
        )
    }
}

const PurchaseOrderAction = (params) => {

    const { t } = useTranslation()

    const { row, user, config, setIsLoading } = params
    const uploaded_driver_bol = row?.uploaded_driver_bol

    const [openUpdate, setOpenUpdate] = useState(false)

    const handleOpenUpdate = () => {
        setOpenUpdate(!openUpdate)
        localStorage.setItem('prevStatusPurchase', row.po_status_id)
    }

    const deleteCollection = async () => {
        const isConfirm = await Swal.fire({
            title: t('title_delete') + t('del_purchaseOrder') + row.po_number + "?",
            text: t('text_delete'),
            icon: 'warning',
            customClass: 'error',
            showCloseButton: true,
            iconColor: '#FF0000',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: t('confirmButtonText_delete'),
            cancelButtonText: t('cancel')
        }).then((result) => {
            return result.isConfirmed
        })

        if (!isConfirm) {
            return
        }

        setIsLoading(true)
        await axios.delete(`/api/purchase-orders/${row.id}`, config)
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    customClass: 'success',
                    showCloseButton: true,
                    iconColor: '#00B78E',
                    text: data.success.message
                })
            })
            .catch(({ response }) => {
                handleAxiosError({response: response})
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const renderActions = () => {
        const automatedBolUrl = `${process.env.REACT_APP_BACKEND_URL}/api/broker-transactions/${row.broker_transaction?.id ?? row.broker_transaction}/proof-of-delivery?stream=view&party=supplier&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`

        return user?.role == 'vendor_user' ? (
            <>
                <div className='flex justify-between'>
                    <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <a href={uploaded_driver_bol ? uploaded_driver_bol?.document_url : automatedBolUrl} target="_blank">
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                    <i className="fa-solid fa-print"></i>
                                </span>
                            </a>
                        </div>
                    </Tooltip>

                    <Tooltip disableInteractive title={t('open')} placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <Link to={`/purchase-orders/${row.broker_transaction?.id ?? row.broker_transaction}`} >
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200">
                                    <i className="fa-solid fa-eye"></i>
                                </span>
                            </Link>
                        </div>
                    </Tooltip>
                </div>
            </>
        ) : (
            <>
                <div className='flex justify-between'>
                    <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                        <div style={{ color: 'rgba(0,0,0,.54)' }}>
                            <a href={process.env.REACT_APP_BACKEND_URL + `/api/purchase-orders/${row.id}/proof-of-delivery?CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                        </div>
                    </Tooltip>

                    {row.po_status_id === 1 || row.po_status_id === 3 ?
                        (<Tooltip disableInteractive title={t('update')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/purchase-order/${row.id}`} state={{ route: '/collections' }} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={handleOpenUpdate}><i className="fa-solid fa-pencil"></i></span></Link>
                            </div>
                        </Tooltip>)
                        :
                        (<Tooltip disableInteractive title={t('open')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <Link to={`/purchase-order/${row.id}`} state={{ route: '/collections' }}><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={handleOpenUpdate}><i className="fa-solid fa-eye"></i></span></Link>
                            </div>
                        </Tooltip>)
                    }

                    {(row.po_status_id < 11 || !isEqual(row.po_status, 'invoiced')) ? (
                        <Tooltip disableInteractive title={t('delete')} placement='bottom'>
                            <div style={{ color: 'rgba(0,0,0,.54)' }}>
                                <span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200" onClick={deleteCollection}><i className="fa-solid fa-trash"></i></span>
                            </div>
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </div>
            </>
        )
    }

    return renderActions()
}

const ShowDate = (params) => {

    return (
        <div>
            <p>{dayjs(params.params.row.requested_collection_date).format("YYYY-MM-DD")}</p>
        </div>
    )
}

export const SoNumber = (params) => {

    const { t } = useTranslation()
    return (
        <div>
            {
                params.params.row.type == 'STREET COLLECTION' ?
                    <Tooltip disableInteractive title={t('street_collection')}>
                        <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(211, 211, 30) transparent transparent rgb(211, 211, 30)` }}></div>
                    </Tooltip>
                    :
                    params.params.row.type == '3PL' ?
                        <Tooltip disableInteractive title={t('3PL')}>
                            <div style={{ position: 'absolute', top: 0, left: 0, border: '7.5px solid', borderColor: `rgb(76, 175, 80) transparent transparent rgb(76, 175, 80)` }}></div>
                        </Tooltip>
                        :
                        null
            }

            <p>{params.params.row.po_number}</p>
        </div>
    )
}
